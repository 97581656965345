.checkout-wrapper {
  .loading-backdrop {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.80);
    z-index: 1500;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .checkout-detail-wrapper {
    max-width: 1110px;
    padding: 45px 15px;
    margin: 0 auto;
    @media screen and (max-width: 1199px) {
      padding: 30px 15px;
    }
    .checkout-login,
    .coupon-wrapper {
      background: #f7f7f7;
      border: 1px solid #e93434;
      border-radius: 10px;
      padding: 20px 40px;
      margin-bottom: 40px;
      @media screen and (max-width: 1440px) {
        padding: 20px 20px;
      }
      @media screen and (max-width: 1199px) {
        margin-bottom: 20px;
      }
      .login-label {
        font-size: 24px;
        line-height: 32px;
        font-weight: 700;
        color: #606060;
        text-align: left;
        margin-bottom: 30px;
        @media screen and (max-width: 1600px) {
          font-size: 20px;
          line-height: 28px;
        }
        @media screen and (max-width: 1440px) {
          font-size: 18px;
          line-height: 26px;
        }
        @media screen and (max-width: 1199px) {
          margin-bottom: 20px;
        }
        @media screen and (max-width: 767px) {
          font-size: 16px;
          line-height: 24px;
        }
        a {
          color: #e93434;
          padding-left: 10px;
          text-decoration: none;
          cursor: pointer !important;
        }
      }
      form {
        .form-field {
          width: calc(25% - 10px);
          align-items: flex-end;
          padding-right: 10px;
          @media screen and (max-width: 991px) {
            width: calc(33.33% - 10px);
            margin-bottom: 20px;
          }
          @media screen and (max-width: 767px) {
            width: calc(50% - 10px);
          }
          @media screen and (max-width: 575px) {
            width: 100%;
          }
          &:last-child {
            @media screen and (max-width: 991px) {
              margin-bottom: 0;
            }
            @media screen and (max-width: 767px) {
              margin-bottom: 20px;
            }
          }
          button {
            &:hover {
              border: 1px solid #e93434;
              background: #e93434;
              color: #fff;
            }
          }
        }.form-signup {
          margin-top: 20px;
          align-self: center;
        }
      }
      .coupon-label {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        @media screen and (max-width: 767px) {
        }
        label {
          font-size: 24px;
          line-height: 32px;
          font-weight: 700;
          color: #606060;
          margin-right: 20px;
          @media screen and (max-width: 1600px) {
            font-size: 20px;
            line-height: 28px;
          }
          @media screen and (max-width: 1440px) {
            font-size: 18px;
            line-height: 26px;
          }
          @media screen and (max-width: 767px) {
            margin-bottom: 15px;
            font-size: 16px;
            line-height: 24px;
          }
        }
        input {
          background: #f7f7f7;
          height: 40px;
          border: 1px solid #e93434;
          border-radius: 5px;
          margin-right: 20px;
          padding: 0 12px;
          @media screen and (max-width: 1440px) {
            height: 35px;
          }
          @media screen and (max-width: 767px) {
            margin-bottom: 20px;
          }
          &:focus {
            outline: none;
          }
        }
        button {
          height: 40px;
          background: #e93434;
          color: #fff;
          border-radius: 5px;
          min-width: 180px;
          font-size: 20px;
          display: flex;
          align-items: center;
          justify-content: center;
          text-transform: capitalize;
          font-weight: 700;
          border: 1px solid #e93434;
          outline: none;
          cursor: pointer !important;
          @media screen and (max-width: 1600px) {
            font-size: 18px;
          }
          @media screen and (max-width: 1440px) {
            font-size: 16px;
          }
          @media screen and (max-width: 1199px) {
            height: 38px;
          }
          @media screen and (max-width: 767px) {
            margin-bottom: 15px;
          }
        }
      }
    }
  }
}


.billing-wrapper {
  display: flex;
  flex-wrap: wrap;
  margin-top: 60px;
  @media screen and (max-width: 1199px) {
    margin-top: 50px;
  }
  @media screen and (max-width: 800px) {
    flex-direction: column;
  }
  @media screen and (max-width: 767px) {
    margin-top: 40px;
  }
  .billing-detail {
    flex: 1;
    margin-right: 40px;
    @media screen and (max-width: 1199px) {
      margin-right: 30px;
    }
    @media screen and (max-width: 800px) {
      margin-right: 0;
    }
    .form-field {
        width: calc(50% - 25px);
        @media screen and (max-width: 1199px) {
          width: calc(50% - 10px);
        }
        @media screen and (max-width: 575px) {
          width: 100%;
        }
        .MuiFormControl-root,
        .MuiInputBase-root {
          min-width: 100%;
          text-align: left;
          background: #f7f7f7;
          input {
            width: 100%;
            height: 58px;
            border: 1px solid #e93434;
            border-radius: 0;
            background: #f7f7f7;
            padding: 0 12px;
            @media screen and (max-width: 1600px) {
              height: 50px;
            }
            @media screen and (max-width: 1440px) {
              height: 45px;
            }
            @media screen and (max-width: 1199px) {
              height: 40px;
            }
            @media screen and (max-width: 767px) {
              font-size: 14px;
            }
            &:focus {
              outline: none;
            }
          }
          .MuiOutlinedInput-notchedOutline {
            border-color: #e93434;
            border-radius: 0;
          }
          & + .MuiFormControl-root {
            margin-top: 24px;
          }
          .MuiSelect-select {
            em {
              font-style: normal;
            }
          }
          .MuiSelect-select {
            padding: 0 12px;
            height: 58px;
            min-height: 58px;
            display: flex;
            align-items: center;
            @media screen and (max-width: 1600px) {
              height: 50px;
              min-height: 50px;
            }
            @media screen and (max-width: 1440px) {
              height: 45px;
              min-height: 45px;
            }
            @media screen and (max-width: 1199px) {
              height: 40px;
              min-height: 40px;
            }
            @media screen and (max-width: 767px) {
              font-size: 14px;
            }
          }
        }
        &.w-100 {
          width: 100%;
        }
      }
    h2 {
      text-align: left;
      font-size: 36px;
      line-height: 47px;
      color: #e93434;
      text-transform: capitalize;
      margin-bottom: 30px;
      @media screen and (max-width: 1600px) {
        font-size: 32px;
        line-height: 42px;
      }
      @media screen and (max-width: 1440px) {
        font-size: 28px;
        line-height: 38px;
      }
      @media screen and (max-width: 1199px) {
        font-size: 26px;
        line-height: 36px;
        margin-bottom: 20px;
      }
      @media screen and (max-width: 991px) {
        font-size: 24px;
        line-height: 34px;
      }
      @media screen and (max-width: 767px) {
        font-size: 22px;
        line-height: 32px;
      }
    }
    form {
      display: flex;
      flex-wrap: wrap;
      column-gap: 50px;
      row-gap: 30px;
      @media screen and (max-width: 1199px) {
        row-gap: 20px;
        column-gap: 20px;
      }
      .form-field {
        width: calc(50% - 25px);
        @media screen and (max-width: 1199px) {
          width: calc(50% - 10px);
        }
        @media screen and (max-width: 575px) {
          width: 100%;
        }
        .MuiFormControl-root,
        .MuiInputBase-root {
          min-width: 100%;
          text-align: left;
          background: #f7f7f7;
          input {
            width: 100%;
            height: 58px;
            border: 1px solid #e93434;
            border-radius: 0;
            background: #f7f7f7;
            padding: 0 12px;
            @media screen and (max-width: 1600px) {
              height: 50px;
            }
            @media screen and (max-width: 1440px) {
              height: 45px;
            }
            @media screen and (max-width: 1199px) {
              height: 40px;
            }
            @media screen and (max-width: 767px) {
              font-size: 14px;
            }
            &:focus {
              outline: none;
            }
          }
          .MuiOutlinedInput-notchedOutline {
            border-color: #e93434;
            border-radius: 0;
          }
          & + .MuiFormControl-root {
            margin-top: 24px;
          }
          .MuiSelect-select {
            em {
              font-style: normal;
            }
          }
          .MuiSelect-select {
            padding: 0 12px;
            height: 58px;
            min-height: 58px;
            display: flex;
            align-items: center;
            @media screen and (max-width: 1600px) {
              height: 50px;
              min-height: 50px;
            }
            @media screen and (max-width: 1440px) {
              height: 45px;
              min-height: 45px;
            }
            @media screen and (max-width: 1199px) {
              height: 40px;
              min-height: 40px;
            }
            @media screen and (max-width: 767px) {
              font-size: 14px;
            }
          }
        }
        &.w-100 {
          width: 100%;
        }
      }
    }
    .billing-add {
    }
    .shipping-add {
      margin-top: 50px;
    }
  }
  .order-detail {
    max-width: 385px;
    width: 100%;
    @media screen and (max-width: 800px) {
      margin-top: 40px;
      max-width: 100%;
    }
    h2 {
      text-align: left;
      font-size: 36px;
      line-height: 47px;
      color: #e93434;
      text-transform: capitalize;
      margin-bottom: 30px;
      @media screen and (max-width: 1600px) {
        font-size: 32px;
        line-height: 42px;
      }
      @media screen and (max-width: 1440px) {
        font-size: 28px;
        line-height: 38px;
      }
      @media screen and (max-width: 1199px) {
        font-size: 26px;
        line-height: 36px;
        margin-bottom: 20px;
      }
      @media screen and (max-width: 991px) {
        font-size: 24px;
        line-height: 34px;
      }
      @media screen and (max-width: 767px) {
        font-size: 22px;
        line-height: 32px;
      }
    }
    place-order-button {
      height: 40px;
      background: #e93434;
      color: #fff;
      border-radius: 5px;
      min-width: 180px;
      font-size: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-transform: capitalize;
      font-weight: 700;
      border: 1px solid #e93434;
      outline: none;
      cursor: pointer !important;
      @media screen and (max-width: 1600px) {
        font-size: 18px;
      }
      @media screen and (max-width: 1440px) {
        font-size: 16px;
      }
      @media screen and (max-width: 1199px) {
        height: 38px;
      }
    }
    .cart-total-table {
      .tr {
        border-bottom: 1px solid #bcbcbc;
        display: flex;
        align-items: center;
        .th {
          text-align: left;
          padding: 12px;
          font-size: 20px;
          line-height: 28px;
          color: #606060;
          font-weight: 700;
          text-transform: capitalize;
          width: 60%;
          @media screen and (max-width: 1440px) {
            font-size: 18px;
            line-height: 26px;
            padding: 10px;
          }
          @media screen and (max-width: 1199px) {
            font-size: 16px;
            line-height: 24px;
            padding: 8px 10px;
          }
          @media screen and (max-width: 767px) {
            font-size: 14px;
            line-height: 22px;
          }
        }
        .td {
          text-align: left;
          padding: 12px;
          font-size: 20px;
          line-height: 28px;
          color: #292929;
          font-weight: 700;
          text-transform: capitalize;
          width: 40%;
          @media screen and (max-width: 1440px) {
            font-size: 18px;
            line-height: 26px;
            padding: 10px;
          }
          @media screen and (max-width: 1199px) {
            font-size: 16px;
            line-height: 24px;
            padding: 8px 10px;
          }
          @media screen and (max-width: 767px) {
            font-size: 14px;
            line-height: 22px;
          }
        }
      }
    }
  }
}

// general form
form {
  display: flex;
  flex-wrap: wrap;
  .form-field {
    display: flex;
    flex-wrap: wrap;
    label {
      text-align: left;
      width: 100%;
      font-size: 24px;
      font-weight: 700;
      line-height: 32px;
      color: #606060;
      margin-bottom: 15px;
      @media screen and (max-width: 1600px) {
        font-size: 20px;
        line-height: 28px;
      }
      @media screen and (max-width: 1440px) {
        font-size: 18px;
        line-height: 26px;
      }
      @media screen and (max-width: 1199px) {
        font-size: 16px;
        line-height: 24px;
      }
      @media screen and (max-width: 767px) {
        font-size: 14px;
        line-height: 22px;
        margin-bottom: 10px;
      }
    }
    .MuiFormControl-root {
      min-width: 210px;
      @media screen and (max-width: 767px) {
        max-width: 100%;
        min-width: 100%;
      }
      input {
        width: 100%;
        height: 38px;
        border: 1px solid #e93434;
        border-radius: 5px;
        background: #ffffff;
        padding: 0 12px;
        @media screen and (max-width: 1440px) {
          height: 35px;
        }
        &::placeholder {
          font-size: 20px;
          color: #7a7a7a;
          @media screen and (max-width: 1600px) {
            font-size: 18px;
          }
          @media screen and (max-width: 1440px) {
            font-size: 16px;
          }
        }
        &:focus {
          outline: none;
        }
      }
      textarea {
        &::placeholder {
          font-size: 20px;
          color: #7a7a7a;
        }
      }
      .MuiSelect-select {
        width: 100%;
      }
      fieldset {
        border-color: rgb(0 0 0 / 0%);
      }
    }
    .MuiFormControlLabel-root {
      margin: 0;
    }
    button {
      height: 40px;
      background: #e93434;
      color: #fff;
      border-radius: 5px;
      min-width: 180px;
      font-size: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-transform: capitalize;
      font-weight: 700;
      border: 1px solid #e93434;
      outline: none;
      cursor: pointer !important;
      @media screen and (max-width: 1600px) {
        font-size: 18px;
      }
      @media screen and (max-width: 1440px) {
        font-size: 16px;
      }
      @media screen and (max-width: 1199px) {
        height: 38px;
      }
    }
  }
}
