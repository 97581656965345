.card {
  width: 100%;
  border-radius: 20px;
  background-color: #f6f6f6;
  overflow: hidden;
  min-height: 720px;
  height: 100%;
  .card-title {
    color: #f6f6f6;
    font-weight: 700;
    font-size: 36px;
    background-color: #e93434;
    padding: 10px;
    min-height: 63px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .card-calender {
    display: flex;
    height: calc(720px - 63px);
    .card-day {
      flex: 1;
      // padding: 20px 20px 0;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      &:nth-of-type(even) {
        background-color: #292929;
        color: #f6f6f6;
        .card-day-title {
          border-bottom: 2px solid #fff;
        }
      }
      .card-day-title {
        font-weight: 700;
        font-size: 24px;
        padding: 15px;
        border-bottom: 2px solid #606060;
      }
      .card-dishes {
        font-weight: 700;
        font-size: 24px;
        text-align: center;
        text-transform: uppercase;
        padding: 20px;
      }
    }
  }
  @media (max-width: 1025px) {
    .card-title {
      font-size: 24px;
    }
    .card-calender {
      .card-day {
        .card-day-title {
          font-size: 18px;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 20px 0;
          font-weight: 900;
        }
        .card-dishes {
          font-size: 18px;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 20px 0;
        }
      }
    }
  }
  @media (max-width: 750px) {
    margin: 10px;
    margin-bottom: 30px;
    width: auto;
    .card-title {
      font-size: 24px;
    }
    .card-calender {
      .card-day {
        .card-day-title {
          font-size: 18px;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 20px 0;
          font-weight: 900;
        }
        .card-dishes {
          font-size: 17px;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 20px 0;
        }
      }
    }
  }
}
