.faq-wrapper{
    .loading-backdrop {
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.80);
        z-index: 1500;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .faq-content-wrapper{
        max-width: 1110px;
        padding: 35px 15px;
        margin: 0 auto;
        @media screen and (max-width: 767px){
            padding: 30px 15px;
        }
        .section-title{
            font-size: 36px;
            line-height: 47px;
            font-weight: 700;
            text-align: center;
            margin-bottom: 35px;
            color: #606060;
            @media screen and (max-width: 1600px){
                font-size: 32px;
                line-height: 44px;
            }
            @media screen and (max-width: 1440px){
                font-size: 28px;
                line-height: 40px;
            }
            @media screen and (max-width: 1199px){
                font-size: 26px;
                line-height: 38px;
            }
            @media screen and (max-width: 767px){
                font-size: 24px;
                line-height: 36px;
                margin-bottom: 30px;
            }
        }
        .faq-content{
            .questions{
                text-align: left;
                padding-bottom: 35px;
                @media screen and (max-width: 1600px){
                    padding-bottom: 20px;
                }
                @media screen and (max-width: 767px){
                    padding-bottom: 15px;
                }
                .question,.ans{
                    font-size: 24px;
                    line-height: 32px;
                    font-weight: 700;
                    color: #606060;
                    margin: 0 0 15px;
                    @media screen and (max-width: 1600px){
                        font-size: 22px;
                        line-height: 30px;
                        margin: 0 0 10px;
                    }
                    @media screen and (max-width: 1440px){
                        font-size: 20px;
                        line-height: 28px;
                    }
                    @media screen and (max-width: 1199px){
                        font-size: 18px;
                        line-height: 26px;
                    }
                    @media screen and (max-width: 767px){
                        font-size: 16px;
                        line-height: 24px;
                        margin: 0 0 5px;
                    }
                    span{
                        padding-left: 10px;
                    }
                }
            }
        }
    }
}