.work-wrapper {
  .work-content-wrapper {
    max-width: 1110px;
    padding: 35px 15px;
    margin: 0 auto;
    @media screen and (max-width: 767px) {
      padding: 30px 15px;
    }

    .section-title {
      font-size: 36px;
      line-height: 47px;
      font-weight: 700;
      text-align: center;
      margin-bottom: 45px;
      color: #606060;
      @media screen and (max-width: 1600px) {
        font-size: 32px;
        line-height: 44px;
      }
      @media screen and (max-width: 1440px) {
        font-size: 28px;
        line-height: 40px;
      }
      @media screen and (max-width: 1199px) {
        font-size: 26px;
        line-height: 38px;
      }
      @media screen and (max-width: 767px) {
        font-size: 24px;
        line-height: 36px;
        margin-bottom: 30px;
      }
    }

    .work-content {
      ol {
        padding-left: 20px;
        margin: 0;
        li {
          font-size: 24px;
          line-height: 32px;
          color: #606060;
          font-weight: 700;
          padding-bottom: 30px;
          text-align: left;
          @media screen and (max-width: 1600px) {
            font-size: 22px;
            line-height: 30px;
            padding-bottom: 25px;
          }
          @media screen and (max-width: 1440px) {
            font-size: 18px;
            line-height: 28px;
            padding-bottom: 20px;
          }
          @media screen and (max-width: 1199px) {
            font-size: 16px;
            line-height: 26px;
          }
          @media screen and (max-width: 767px) {
            font-size: 15px;
            line-height: 22px;
          }
        }
      }
      p {
        font-size: 24px;
        line-height: 32px;
        color: #606060;
        font-weight: 700;
        padding-bottom: 20px;
        text-align: left;
        @media screen and (max-width: 1600px) {
          font-size: 22px;
          line-height: 30px;
          padding-bottom: 15px;
        }
        @media screen and (max-width: 1440px) {
          font-size: 18px;
          line-height: 28px;
        }
        @media screen and (max-width: 1199px) {
          font-size: 16px;
          line-height: 26px;
        }
        @media screen and (max-width: 767px) {
          font-size: 15px;
          line-height: 22px;
        }
        .text-uppercase {
          text-transform: uppercase;
        }
        span {
          &:first-child {
            padding-right: 10px;
          }
        }
      }
    }
  }
}
