.main-header {
  width: 100%;
  height: 120px;
  background-color: #292929;
  color: #f7f7f7;
  display: flex;
  position: fixed;
  z-index: 101;
  .main-header-container {
    margin: 0 auto;
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .d-not-none {
      display: none;
    }
    .MuiAvatar-root {
      width: 10%;
      display: flex;
      justify-content: flex-start;
      border-radius: 0px;
      img {
        width: auto;
      }
    }
    .main-header-menu {
      display: flex;
      justify-content: space-evenly;
      flex: 1;
      a {
        text-decoration: none;
      }
      h6 {
        font-weight: 700;
        font-size: 20px;
        color: white;
        text-decoration: none;
      }
    }
    .main-header-menu-icons {
      width: 15%;
      display: flex;
      justify-content: space-around;
      svg {
        height: 25px;
        width: 25px;
        cursor: pointer;
      }
      .close-icon {
        width: 40px;
        height: 40px;
        color: red;
        margin: 0;
      }
    }
    @media (max-width: 950px) {
      .main-header-menu {
        h6 {
          font-size: 15px;
        }
      }
      .main-header-menu-icons {
        svg {
          height: 20px;
          width: 20px;
        }
      }
    }
  }
  @media (max-width: 790px) {
    height: 70px;
    .main-header-container {
      .MuiAvatar-root {
        width: 50px;
        height: 50px;
      }
      .main-header-menu {
        display: none;
      }
      .main-header-menu-icons {
        display: flex;
        width: auto;
        .d-none {
          display: none;
        }
        .d-not-none {
          display: block;
        }
        svg {
          margin-left: 15px;
          margin-right: 10px;
          height: 25px;
          width: 25px;
        }
      }
    }
  }
  .main-header-drawer {
    transition: all 300ms ease-in-out;
    position: fixed;
    top: 70px;
    left: 0;
    width: 100%;
    height: 100vh;
    background: #292929;
    z-index: 10;
    .main-header-drawer-container {
      margin: 10px auto;
      width: 90%;
      .main-header-drawer-menus {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        a {
          text-decoration: none;
        }
        h6 {
          color: #f6f6f6;
          font-weight: 700;
          font-size: 20px;
          margin-bottom: 10px;
          cursor: pointer;
        }
      }
      .MuiPaper-root {
        background-color: transparent;
        color: #f6f6f6;
        box-shadow: none;
        border: 0;
        margin: 0;
        .expand-icon {
          color: red;
          height: 20px;
          width: 20px;
        }
        &::before {
          height: 0px;
        }
        .MuiButtonBase-root {
          padding: 0;
          min-height: auto;
          .MuiAccordionSummary-content {
            margin: 0;
            p {
              font-size: 20px;
              font-weight: 700;
              font-family: "Lato", sans-serif !important;
            }
          }
        }
        .MuiCollapse-root {
          .MuiCollapse-wrapper {
            .MuiAccordionDetails-root {
              padding-left: 25px;
              h6 {
                width: fit-content;
                font-size: 20px;
                font-weight: 700;
                margin-bottom: 10px;
              }
            }
          }
        }
      }
      .Mui-expanded {
        margin: 0;
        padding: 0;
      }
      .login-btn {
        width: 100%;
        background: #e93434;
        text-transform: capitalize;
        color: #292929;
        font-size: 20px;
        font-weight: 700;
        border-radius: 5px;
      }
    }
  }
}

.css-sox5kk-MuiBackdrop-root{
  position: initial !important;
}
