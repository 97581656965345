.footer {
  background-color: #292929;
  padding: 40px;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .footer-links {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    .footer-link {
      color: white;
      font-weight: 600;
      text-decoration: none;
      margin-bottom: 30px;
    }
  }
  .footer-social-icons {
    width: 50%;
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 30px;
    svg {
      cursor: pointer;
      width: 30px;
      height: 30px;
    }
  }
  .footer-detail {
    color: #f6f6f6;
    font-family: "Merriweather", serif;
  }
  @media (max-width: 780px) {
    .footer-links {
      flex-direction: column;
      margin-bottom: 20px;
      .footer-link {
        margin-bottom: 0.5rem;
        font-size: 14px;
      }
    }
    .footer-social-icons {
      width: 70%;
      justify-content: space-between;
    }
    .footer-detail {
      font-size: 12px;
    }
  }
}
