.my-profile {
  padding-top: 120px;
  @media (max-width: 790px) {
    padding-top: 70px;
  }
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .my-profile-container {
    max-width: 500px;
    width: 100%;
    margin: 20px auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media (max-width: 600px) {
      margin: 20px;
    }
    .profile-logo {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .MuiAvatar-root {
        margin-top: 20px;
        width: 200px;
        height: 200px;
        border: 3px solid #f7962a;
        font-size: 70px;
        letter-spacing: 5px;
        @media (max-width: 600px) {
          width: 150px;
          height: 150px;
          font-size: 50px;
        }
      }
    }
    .my-profile-form {
      width: 100%;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      padding: 2%;
      .my-profile-form-email-container,
      .my-profile-form-firstname-container,
      .my-profile-form-lastname-container {
        width: 100%;
        .my-profile-form-email-label,
        .my-profile-form-firstname-label,
        .my-profile-form-lastname-label {
          color: #606060;
          font-size: 20px;
          text-align: left;
          margin: 10px 0;
        }
      }
      .my-profile-form-btn-container {
        display: flex;
        width: 100%;
        .my-profile-form-button {
          margin-top: 20px;
          text-transform: capitalize;
          background-color: #e93434;
          color: #f6f6f6;
          padding: 2px 34px;
          font-weight: 600;
          font-size: 20px;
          width: 100%;
        }
        .change-pass-btn {
          width: 60%;
        }
        .save-btn {
          width: 40%;
        }
      }
      .MuiTextField-root {
        width: 100%;
        .MuiOutlinedInput-root {
          border-radius: 7px;
          background-color: #f6f6f6;
        }
      }
    }
  }
}
