.product-detail {
  padding-top: 120px;
  @media (max-width: 790px) {
    padding-top: 70px;
  }
  .product-detail-image {
    width: 100%;
  }
  .product-detail-container {
    margin: 4%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    color: #606060;

    .product-detail-title {
      font-size: 48px;
      font-weight: 700;
      margin-bottom: 10px;
    }
    .product-detail-rating {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      font-family: "Merriweather", serif;
      .rating-number {
        margin-right: 10px;
        color: white;
        background-color: #66c140;
        padding: 5px 10px;
        border-radius: 4px;
      }
    }
    .product-detail-desc {
      font-family: "Merriweather", serif;
      font-weight: 400;
      font-size: 24px;
      text-align: left;
    }
    .product-detail-category {
      font-family: "Merriweather", serif;
      font-size: 24px;
      margin-bottom: 10px;
      text-align: left;
      b {
        font-family: "Lato", sans-serif;
      }
    }
    .product-detail-price {
      font-family: "Merriweather", serif;
      font-weight: 400;
      font-size: 24px;
      margin-bottom: 10px;
    }
    .product-detail-options {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 30px;
      width: 100%;
      .product-detail-option {
        display: flex;
        width: 100%;
        .product-detail-select {
          width: 100%;
          max-width: 300px;
          margin-right: 10px;
          margin-right: 10px;
          .MuiSelect-select {
            text-align: left;
          }
          fieldset {
            border: 1px solid #e93434 !important;
            top: 0;
            legend {
              display: none;
            }
          }
        }
        .product-detail-cart {
          display: flex;
          width: 100%;
          .product-detail-numbers {
            background-color: #f6f6f6;
            border: 1px solid #e93434;
            border-radius: 5px;
            padding: 0px 10px;
            display: flex;
            align-items: center;
            justify-content: space-around;
            margin-right: 10px;
            div {
              margin: 0 10px;
            }
            .product-btn {
              font-size: 20px;
              cursor: pointer;
              font-weight: 700;
              line-height: 0.5;
              padding: 6px;
              border-radius: 50%;
              border: 1px solid #e93434;
            }
            .product-number {
              font-size: 20px;
              font-weight: 700;
            }
          }
          .product-detail-add-to-cart {
            background: #e93434;
            border-radius: 5px;
            font-weight: 700;
            font-size: 20px;
            line-height: 0;
            text-transform: capitalize;
            color: white;
            width: 100%;
            max-width: 250px;
          }
        }
      }
      .product-detail-social-media {
        display: flex;
        svg {
          font-size: 30px;
          margin-right: 10px;
          fill: #fba4a4;
          cursor: pointer;
        }
      }
    }
    .product-detail-related-products {
      width: 100%;
      h1 {
        font-weight: 700;
        font-size: 40px;
        text-align: left;
        margin-bottom: 20px;
      }
      .product-detail-related-products-cards {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 10px;
        @media (max-width: 830px) {
          grid-template-columns: repeat(2, 1fr);
        }
        @media (max-width: 600px) {
          grid-template-columns: repeat(1, 1fr);
        }
      }
    }
  }
  @media (max-width: 965px) {
    .product-detail-options {
      flex-direction: column;
      align-items: flex-start;
      .product-detail-option {
        margin-bottom: 10px;
      }
      .product-detail-social-media {
        width: 100%;
        svg {
          margin-right: 30px !important;
        }
      }
    }
  }
  @media (max-width: 741px) {
    .product-detail-container {
      .product-detail-options {
        .product-detail-option {
          flex-direction: column;
          .product-detail-select {
            margin-bottom: 10px;
            max-width: none;
          }
          .product-detail-cart {
            height: 57px;
            width: 100%;
            .product-detail-add-to-cart {
              max-width: none;
              font-size: 14px;
            }
          }
        }
      }
    }
  }
  @media (max-width: 630px) {
    .product-detail-container {
      .product-detail-title {
        font-size: 24px;
      }
      .product-detail-desc {
        font-size: 12px;
        margin: 10px 0;
      }
      .product-detail-category {
        font-size: 14px;
      }
      .product-detail-price {
        font-size: 16px;
      }
      .product-detail-options {
        .product-detail-option {
          .product-detail-select {
            max-width: none;
          }
        }
      }
      .product-detail-related-products {
        h1 {
          font-size: 20px;
        }
      }
    }
  }
}
