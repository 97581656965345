.my-order-wrapper {
  .order-main-wrapper {
    max-width: 1110px;
    padding: 45px 15px;
    margin: 0 auto;

    @media screen and (max-width: 1199px) {
      padding: 30px 15px;
    }

    .loading-backdrop {
      position: fixed;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background-color: rgba(0, 0, 0, 0.9);
      z-index: 1500;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .title {
      font-size: 40px;
      line-height: 52px;
      text-transform: capitalize;
      font-weight: 700;
      color: #606060;
      margin-bottom: 30px;

      @media screen and (max-width: 1600px) {
        font-size: 32px;
        line-height: 44px;
      }

      @media screen and (max-width: 1440px) {
        font-size: 30px;
        line-height: 42px;
      }

      @media screen and (max-width: 1199px) {
        font-size: 28px;
        line-height: 40px;
      }

      @media screen and (max-width: 767px) {
        font-size: 26px;
        line-height: 38px;
        margin-bottom: 20px;
      }

      @media screen and (max-width: 575px) {
        font-size: 24px;
        line-height: 36px;
      }
    }

    .order-main {
      h3 {
        font-size: 36px;
        line-height: 47px;
        font-weight: 700;
        color: #606060;
        margin-bottom: 25px;
        text-transform: capitalize;
        text-align: left;

        @media screen and (max-width: 1600px) {
          font-size: 30px;
          line-height: 40px;
        }

        @media screen and (max-width: 1440px) {
          font-size: 28px;
          line-height: 38px;
        }

        @media screen and (max-width: 1199px) {
          font-size: 26px;
          line-height: 36px;
        }

        @media screen and (max-width: 991px) {
          font-size: 24px;
          line-height: 34px;
        }

        @media screen and (max-width: 767px) {
          font-size: 22px;
          line-height: 32px;
          margin-bottom: 20px;
        }

        @media screen and (max-width: 575px) {
          font-size: 20px;
          line-height: 30px;
          margin-bottom: 15px;
        }
      }

      .order-list {
        .order-item {
          width: 100%;
          display: flex;
          border: 1px solid #e93434;
          border-radius: 40px;
          background: #f7f7f7;
          margin-bottom: 20px;

          @media screen and (max-width: 767px) {
            border-radius: 20px;
          }

          @media screen and (max-width: 575px) {
            flex-direction: column;
            border-radius: 10px;
          }

          &:last-child {
            margin-bottom: 0;
          }

          .order-img {
            max-width: 315px;
            width: 100%;
            min-height: 260px;
            border-radius: 40px;
            overflow: hidden;

            @media screen and (max-width: 1600px) {
              max-width: 300px;
              min-height: 250px;
            }

            @media screen and (max-width: 1199px) {
              max-width: 280px;
              min-height: 240px;
            }

            @media screen and (max-width: 991px) {
              max-width: 250px;
              min-height: 220px;
            }

            @media screen and (max-width: 767px) {
              max-width: 180px;
              min-height: 180px;
              border-radius: 20px;
            }

            @media screen and (max-width: 575px) {
              max-width: 100%;
              min-height: 180px;
              border-radius: 10px 10px 0 0;
              max-height: 240px;
            }

            @media screen and (max-width: 480px) {
              max-height: 220px;
            }

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }

          .order-detail {
            text-align: left;
            padding: 25px 30px;

            @media screen and (max-width: 1199px) {
              padding: 20px 20px;
            }

            @media screen and (max-width: 575px) {
              padding: 15px;
            }

            .title {
              font-size: 36px;
              line-height: 47px;
              font-weight: 700;
              color: #606060;
              margin-bottom: 20px;
              text-transform: capitalize;

              @media screen and (max-width: 1600px) {
                font-size: 32px;
                line-height: 44px;
                margin-bottom: 15px;
              }

              @media screen and (max-width: 1440px) {
                font-size: 28px;
                line-height: 40px;
              }

              @media screen and (max-width: 1199px) {
                font-size: 26px;
                line-height: 36px;
              }

              @media screen and (max-width: 991px) {
                font-size: 24px;
                line-height: 34px;
              }

              @media screen and (max-width: 767px) {
                font-size: 20px;
                line-height: 30px;
                margin-bottom: 10px;
              }
            }

            .order-number {
              font-size: 24px;
              line-height: 32px;
              font-weight: 700;
              color: #606060;
              margin-bottom: 25px;

              @media screen and (max-width: 1600px) {
                font-size: 22px;
                line-height: 30px;
                margin-bottom: 20px;
              }

              @media screen and (max-width: 1440px) {
                font-size: 20px;
                line-height: 28px;
              }

              @media screen and (max-width: 1199px) {
                font-size: 18px;
                line-height: 26px;
                margin-bottom: 15px;
              }

              @media screen and (max-width: 991px) {
                font-size: 16px;
                line-height: 24px;
              }

              @media screen and (max-width: 767px) {
                font-size: 14px;
                line-height: 22px;
                margin-bottom: 10px;
              }
            }

            .delivery-date {
              font-size: 24px;
              line-height: 32px;
              font-weight: 700;
              color: #606060;
              margin-bottom: 25px;

              @media screen and (max-width: 1600px) {
                font-size: 22px;
                line-height: 30px;
                margin-bottom: 20px;
              }

              @media screen and (max-width: 1440px) {
                font-size: 20px;
                line-height: 28px;
              }

              @media screen and (max-width: 1199px) {
                font-size: 18px;
                line-height: 26px;
                margin-bottom: 15px;
              }

              @media screen and (max-width: 991px) {
                font-size: 16px;
                line-height: 24px;
              }

              @media screen and (max-width: 767px) {
                font-size: 14px;
                line-height: 22px;
                margin-bottom: 10px;
              }
            }

            .delivery-item {
              font-size: 24px;
              line-height: 32px;
              font-weight: 400;
              color: #606060;
              text-transform: uppercase;

              @media screen and (max-width: 1600px) {
                font-size: 22px;
                line-height: 30px;
              }

              @media screen and (max-width: 1440px) {
                font-size: 20px;
                line-height: 28px;
              }

              @media screen and (max-width: 1199px) {
                font-size: 18px;
                line-height: 26px;
              }

              @media screen and (max-width: 991px) {
                font-size: 16px;
                line-height: 24px;
              }

              @media screen and (max-width: 767px) {
                font-size: 14px;
                line-height: 22px;
              }
            }
          }

          .price-detail {
            color: #e93434;
            font-size: 36px;
            line-height: 47px;
            font-weight: 700;
            display: flex;
            align-items: flex-start;
            padding: 25px 40px 25px 0;
            margin-left: auto;

            @media screen and (max-width: 1600px) {
              font-size: 30px;
              line-height: 42px;
            }

            @media screen and (max-width: 1440px) {
              font-size: 28px;
              line-height: 40px;
            }

            @media screen and (max-width: 1199px) {
              padding: 20px 20px;
              font-size: 24px;
              line-height: 34px;
            }

            @media screen and (max-width: 991px) {
              font-size: 22px;
              line-height: 32px;
            }

            @media screen and (max-width: 767px) {
              font-size: 18px;
              line-height: 28px;
            }

            @media screen and (max-width: 575px) {
              padding: 0 15px 15px;
              margin: 0;
            }

            .payment-situation {
              text-transform: capitalize;
              margin-left: 5px;
            }
          }
        }
      }
    }
  }
}