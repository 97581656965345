.cart-wrapper {
  padding-top: 120px;
  @media (max-width: 790px) {
    padding-top: 70px;
  }
  h2 {
    margin-bottom: 20px;
  }
  .loading-backdrop {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.9);
    z-index: 1500;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .product-details-wrapper {
    max-width: 1110px;
    padding: 45px 15px;
    margin: 0 auto;
    @media screen and (max-width: 1199px) {
      padding: 30px 15px;
    }

    .product-details {
      .product-table {
        .product-head {
          display: flex;
          width: 100%;
          border-bottom: 1px solid #c7c7c7;
          @media screen and (max-width: 767px) {
            display: none;
          }
          .tr {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .th {
              text-align: center;
              min-width: 145px;
              padding: 15px 10px;
              color: #606060;
              font-size: 24px;
              line-height: 32px;
              font-weight: 700;
              @media screen and (max-width: 1199px) {
                font-size: 18px;
                line-height: 26px;
                min-width: 120px;
                padding: 12px 10px;
              }
              @media screen and (max-width: 991px) {
                font-size: 16px;
                line-height: 24px;
              }
            }
          }
        }
        .product-body {
          display: flex;
          flex-direction: column;
          width: 100%;
          .tr {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-bottom: 1px solid #c7c7c7;
            @media screen and (max-width: 767px) {
              flex-direction: column;
              align-items: flex-start;
              padding: 15px 0;
            }
            .td {
              text-align: center;
              min-width: 135px;
              padding: 25px 10px;
              color: #606060;
              padding: 8px 15px;
              @media screen and (max-width: 1199px) {
                min-width: 120px;
                padding: 8px 10px;
              }
              @media screen and (max-width: 767px) {
                max-width: 100%;
                text-align: left;
                padding: 8px 0;
              }
              .product-info {
                display: flex;
                @media screen and (max-width: 767px) {
                  flex-direction: column;
                }
                .product-info-detail {
                  display: flex;
                  width: 95%;
                  @media screen and (max-width: 880px) {
                    flex-direction: column;
                  }
                  @media screen and (max-width: 767px) {
                    margin-bottom: 15px;
                  }
                  img {
                    max-width: 165px;
                    min-height: 165px;
                    max-height: 165px;
                    object-fit: cover;
                    margin-right: 20px;
                    @media screen and (max-width: 1199px) {
                      max-width: 135px;
                      min-height: 135px;
                      max-height: 135px;
                      margin-right: 15px;
                    }
                    @media screen and (max-width: 991px) {
                      max-width: 125px;
                      min-height: 125px;
                      max-height: 125px;
                    }
                    @media screen and (max-width: 880px) {
                      margin-right: 0;
                      margin-bottom: 15px;
                    }
                  }
                }
                .detail {
                  .item-detail {
                    width: 100%;
                    margin-bottom: 20px;
                    @media screen and (max-width: 991px) {
                      margin-bottom: 10px;
                    }
                    span {
                      width: 100%;
                      font-size: 14px;
                      line-height: 18px;
                      font-weight: 700;
                      @media screen and (max-width: 1199px) {
                        font-size: 13px;
                        line-height: 17px;
                      }
                      @media screen and (max-width: 1199px) {
                        font-size: 12px;
                        line-height: 15px;
                      }
                    }
                    .product-type {
                      font-size: 13px;
                      color: #a4a0a0;
                      margin-top: 10px;
                    }
                  }
                }
                .delete-icon {
                  width: 24;
                  max-height: 24px;
                  min-height: auto;
                  object-fit: contain;
                  cursor: pointer !important;
                  margin: auto 0 auto 20px;
                  @media screen and (max-width: 1199px) {
                    width: 16px;
                    max-height: 16px;
                  }
                  @media screen and (max-width: 991px) {
                    margin: auto 0 auto 15px;
                  }
                }
              }
              &.price,
              &.total {
                font-weight: 700;
                font-size: 24px;
                line-height: 32px;
                @media screen and (max-width: 1199px) {
                  font-size: 18px;
                  line-height: 26px;
                }
                @media screen and (max-width: 1199px) {
                  font-size: 16px;
                  line-height: 24px;
                }
              }
              .qauntityFull {
                border: 2px solid #e93434;
                border-radius: 5px;
                padding: 8px 15px;
                display: flex;
                justify-content: center;
                align-items: center;
                background: #f7f7f7;
                @media screen and (max-width: 1199px) {
                  padding: 6px 10px;
                }
              }
              .qty {
                color: #292929;
                font-size: 20px;
                line-height: 26px;
                font-weight: 700;
                @media screen and (max-width: 1199px) {
                  font-size: 16px;
                  line-height: 20px;
                }
              }
              .qauntity {
                border: 2px solid #e93434;
                border-radius: 5px;
                padding: 8px 15px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                background: #f7f7f7;
                @media screen and (max-width: 1199px) {
                  padding: 6px 10px;
                }
                .qty {
                  color: #292929;
                  font-size: 20px;
                  line-height: 26px;
                  font-weight: 700;
                  @media screen and (max-width: 1199px) {
                    font-size: 16px;
                    line-height: 20px;
                  }
                }
                .icon {
                  width: 25px;
                  height: 25px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  border: 2px solid #e93434;
                  border-radius: 50%;
                  cursor: pointer !important;
                  @media screen and (max-width: 1199px) {
                    width: 20px;
                    height: 20px;
                  }
                  @media screen and (max-width: 991px) {
                    width: 18px;
                    height: 18px;
                  }
                  img {
                    @media screen and (max-width: 1199px) {
                      width: 8px;
                    }
                  }
                }
              }
            }
          }
        }
        .large-w {
          max-width: 600px;
          width: 100%;
          flex: 1;
          text-align: left !important;
        }
      }
    }

    .coupon-wrapper {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: 20px;
      padding-top: 26px;
      padding-bottom: 48px;
      span {
        font-size: 24px;
        line-height: 32px;
        color: #606060;
        @media screen and (max-width: 1199px) {
          font-size: 20px;
          line-height: 28px;
        }
        @media screen and (max-width: 991px) {
          font-size: 18px;
          line-height: 26px;
        }
        @media screen and (max-width: 767px) {
          font-size: 16px;
          line-height: 24px;
        }
      }
      input {
        border: 1px solid #e93434;
        border-radius: 5px;
        background: #f7f7f7;
        height: 40px;
        padding: 0 12px;
        min-width: 210px;
        outline: none;
        @media screen and (max-width: 991px) {
          height: 35px;
        }
      }
    }
    .cart-total {
      h2 {
        font-size: 36px;
        line-height: 47px;
        color: #e93434;
        margin-bottom: 60px;
        @media screen and (max-width: 1199px) {
          font-size: 30px;
          line-height: 40px;
          margin-bottom: 40px;
        }
        @media screen and (max-width: 991px) {
          font-size: 26px;
          line-height: 36px;
          margin-bottom: 30px;
        }
        @media screen and (max-width: 767px) {
          font-size: 22px;
          line-height: 32px;
        }
      }
      .cart-total-table {
        .tr {
          border-bottom: 1px solid #bcbcbc;
          display: flex;
          align-items: center;
          .th {
            text-align: left;
            padding: 12px;
            font-size: 24px;
            line-height: 32px;
            color: #606060;
            font-weight: 700;
            text-transform: capitalize;
            width: 50%;
            @media screen and (max-width: 1199px) {
              font-size: 18px;
              line-height: 26px;
              padding: 10px;
            }
            @media screen and (max-width: 991px) {
              font-size: 16px;
              line-height: 24px;
              padding: 8px 10px;
            }
            @media screen and (max-width: 767px) {
              font-size: 14px;
              line-height: 22px;
            }
          }
          .td {
            text-align: left;
            padding: 12px;
            font-size: 24px;
            line-height: 32px;
            color: #292929;
            font-weight: 700;
            text-transform: capitalize;
            width: 50%;
            @media screen and (max-width: 1199px) {
              font-size: 18px;
              line-height: 26px;
              padding: 10px;
            }
            @media screen and (max-width: 991px) {
              font-size: 16px;
              line-height: 24px;
              padding: 8px 10px;
            }
            @media screen and (max-width: 767px) {
              font-size: 14px;
              line-height: 22px;
            }
          }
        }
      }
    }
    .cart-product-related-card {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 10px;
      padding: 60px 0;
      @media (max-width: 830px) {
        grid-template-columns: repeat(2, 1fr);
      }
      @media (max-width: 600px) {
        grid-template-columns: repeat(1, 1fr);
      }
    }
    .btn {
      height: 40px;
      border-radius: 5px;
      background: #e93434;
      border: 1px solid #e93434;
      color: #fff;
      text-transform: capitalize;
      padding: 10px 30px;
      font-size: 20px;
      font-weight: 700;
      display: flex;
      align-items: center;
      justify-content: center;
      outline: none;
      border: none;
      cursor: pointer !important;
      @media screen and (max-width: 1199px) {
        font-size: 18px;
      }
      @media screen and (max-width: 991px) {
        font-size: 16px;
      }
      @media screen and (max-width: 767px) {
        height: 40px;
        font-size: 14px;
      }
      &.checkout-btn {
        margin: 50px auto 0;
        height: 45px;
        padding: 0 40px;
        @media screen and (max-width: 767px) {
          height: 40px;
          margin: 40px auto 0;
        }
      }
    }
  }
}
// .ant-popover {
//   width: fit-content;
//   background-color: #f7f7f7;
//   padding: 15px;
//   border: 1px solid;
//   border-radius: 10px;
//   .anticon-exclamation-circle {
//     display: none;
//   }
//   .ant-popover-message-title {
//     font-size: 20px;
//     font-weight: 700;
//   }
// }
