.accordian-payment {
    margin-top: 40px;
  .MuiPaper-root {
    margin: 0 !important;
    border-radius: 0 !important;
    border: 1px solid #ccc;
    box-shadow: none;
    &::before{
        border: none !important;
    }
    &:last-child{
        border-top: 0;
    }
    .MuiAccordionSummary-content {
      margin: 0;
      .MuiTypography-root {
        width: 100%;
        display: flex;
        align-items: center;
      }
    }
  }
  .card-detail {
    display: flex;
    align-items: center;
    .label {
      color: #000;
      font-size: 16px;
      font-weight: 700;
      margin-right: 10px;
    }
    ul {
      display: flex;
      align-items: center;
      list-style-type: none;
      padding: 0;
      flex: 1;
      justify-content: space-between;
    }
  }
  .accordian-detail-data {
    display: flex;
    flex-direction: column;
    > span {
      font-size: 14px;
      color: #333;
      margin-bottom: 15px;
      width: 100%;
      text-align: left;
    }
    form {
      display: flex;
      flex-wrap: wrap;
      .form-field {
        width: 100%;
        margin-bottom: 30px;
        @media screen and (max-width: 1199px){
          margin-bottom: 20px;
        }
        &.w-50 {
          width: 50%;
        }
        label {
          width: 100%;
          font-size: 14px;
          color: #000;
          margin-bottom: 10px;
          line-height: 22px;
          span {
            color: #e93434;
          }
        }
        input {
          width: 100%;
          border: none;
          outline: none;
          &.card-number::placeholder {
            font-size: 24px;
          }
          &::placeholder {
            font-size: 15px;
          }
        }
      }
    }
  }
}

[type="radio"] + span svg {
    opacity: 0;
    width: 0;
    height: 0;
}
[type="radio"]:checked,
[type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
}
[type="radio"]:checked + span,
[type="radio"]:not(:checked) + span
{
    position: relative;
    // padding-left: 28px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    color: #666;
}
[type="radio"]:checked + span:before,
[type="radio"]:not(:checked) + span:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 16px;
    height: 16px;
    border: 1px solid #ddd;
    border-radius: 100%;
    background: #fff;
}
[type="radio"]:checked + span:before{
    border: 1px solid #1976d2;
}
[type="radio"]:checked + span:after,
[type="radio"]:not(:checked) + span:after {
    content: '';
    width: 10px;
    height: 10px;
    background: #F87DA9;
    position: absolute;
    top: 4px;
    left: 4px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}
[type="radio"]:checked + span:after{
    background:#1976d2;
}
[type="radio"]:not(:checked) + span:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}
[type="radio"]:checked + span:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}
