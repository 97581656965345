.homepage {
  padding-top: 120px;
  background-color: #292929;
  @media (max-width: 790px) {
    padding-top: 70px;
  }
  width: 100%;
  position: relative;
  .loading-backdrop {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.80);
    z-index: 1500;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .homepage-main-image-container {
    position: relative;
    .homepage-main-image-swiper {
      .swiper-wrapper {
        .swiper-slide {
          img {
            width: 100%;
          }
        }
      }
    }
    .swiper-slide-btn {
      position: absolute;
      top: 50%;
      transform: translate(0, -50%);
      z-index: 10;
      cursor: pointer;
      line-height: 0;
      svg {
        box-shadow: 0px 0px 23px 10px #e93434;
        background-color: #e93434;
        margin: 0;
        padding: 0;
        fill: white;
        filter: url(#shadow);
      }
    }
    .swiper-slide-prev-btn {
      left: 5%;
    }
    .swiper-slide-next-btn {
      right: 5%;
    }
    .homepage-main-image-details {
      position: absolute;
      z-index: 10;
      top: 50%;
      transform: translate(0, -50%);
      left: 10%;
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      margin: 0px 30px;
      .homepage-main-image-heading-1,
      .homepage-main-image-heading-3 {
        font-family: "Merriweather", serif;
      }
      .homepage-main-image-heading-1,
      .homepage-main-image-heading-2 {
        font-size: 40px;
      }
      .homepage-main-image-heading-1 {
        color: #e93434;
        font-weight: 400;
      }
      .homepage-main-image-heading-2 {
        width: fit-content;
        font-weight: 700;
      }
      .homepage-main-image-heading-3 {
        font-size: 16px;
        line-height: 1;
        width: 35%;
        text-align: left;
        margin: 10px 0;
        font-weight: 400;
      }
      .homepage-main-image-order-button {
        border-radius: 20px;
        background-color: #e93434;
        margin: 15px 0px;
      }
      @media (max-width: 790px) {
        .homepage-main-image-heading-1,
        .homepage-main-image-heading-2 {
          font-size: 24px;
        }
        .homepage-main-image-heading-3 {
          line-height: 16px;
          width: 70%;
          font-size: 12px;
        }
        .homepage-main-image-heading-2,
        .homepage-main-image-heading-3 {
          color: #f6f6f6;
        }
        .homepage-main-image-order-button {
          font-size: 14px;
        }
      }
    }
    .swiper-image-backdrop {
      display: none;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.3);
      z-index: 9;
      @media (max-width: 790px) {
        // display: block;
      }
    }
  }
  // .homepage-mainimage-container {
  //   .homepage-main-image {
  //     width: 100%;
  //   }
  // }
  // .homepage-details {
  //   position: absolute;
  //   top: 50%;
  //   left: 100px;
  //   transform: translate(0%, -50%);
  //   display: flex;
  //   justify-content: center;
  //   align-items: center;
  //   width: 88%;
  //   .homepage-main-image-arrows {
  //     position: relative;
  //     display: flex;
  //     justify-content: center;
  //     align-items: center;
  //     width: 40px;
  //     height: 40px;
  //     cursor: pointer;
  //     > svg {
  //       z-index: 2;
  //       position: absolute;
  //     }
  //     > div {
  //       position: absolute;
  //       box-shadow: 0px 0px 23px 17px #54bf29;
  //       z-index: 0;
  //     }
  //   }
  //   .homepage-main-image-details {
  //     flex: 1;
  //     display: flex;
  //     flex-direction: column;
  //     align-items: flex-start;
  //     justify-content: center;
  //     margin: 0px 30px;
  //     .homepage-main-image-heading-1,
  //     .homepage-main-image-heading-3 {
  //       font-family: "Merriweather", serif;
  //     }
  //     .homepage-main-image-heading-1,
  //     .homepage-main-image-heading-2 {
  //       font-size: 40px;
  //     }
  //     .homepage-main-image-heading-1 {
  //       color: #54bf29;
  //       font-weight: 400;
  //     }
  //     .homepage-main-image-heading-2 {
  //       width: fit-content;
  //     }
  //     .homepage-main-image-heading-3 {
  //       font-size: 16px;
  //       line-height: 1;
  //       width: 35%;
  //       text-align: left;
  //       margin: 10px 0;
  //       font-weight: 400;
  //     }
  //     .homepage-main-image-order-button {
  //       border-radius: 20px;
  //       background-color: #54bf29;
  //       margin: 15px 0px;
  //     }
  //   }
  // }
}
