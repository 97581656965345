.gallery {
  padding-top: 120px;
  @media (max-width: 790px) {
    padding-top: 70px;
  }
  .gallery-main-image {
    width: 100%;
    height: auto;
    img {
      width: 100%;
      object-fit: cover;
    }
  }
  .gallery-quote {
    font-weight: 700;
    font-size: 36px;
    width: 50%;
    margin: 45px auto;
    color: #e93434;
    @media (max-width: 920px) {
      width: 60%;
    }
    @media (max-width: 720px) {
      width: 70%;
      margin: 35px auto;
    }
    @media (max-width: 580px) {
      font-size: 20px;
      margin: 25px auto;
    }
    @media (max-width: 420px) {
      width: 85%;
    }
  }
  .gallery-btn {
    background-color: #e93434;
    color: white;
    text-transform: capitalize;
    line-height: 1;
    font-size: 24px;
    font-weight: 700;
    padding: 10px 20px;
    margin: 20px 0 40px;
    &:hover {
      background-color: #e93434;
      color: white;
    }
    @media (max-width: 580px) {
      font-size: 14px;
      margin: 10px 0 20px;
    }
  }
  .gallery-tabs-container {
    width: 85%;
    margin: 10px auto 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .MuiTabs-root {
      width: 100%;
      .MuiTabs-scroller {
        border-bottom: 2px solid #8b8b8b;
        .MuiTabs-flexContainer {
          justify-content: space-between;
          .MuiButtonBase-root {
            letter-spacing: 1;
            font-weight: 700;
            font-size: 20px;
            color: #8b8b8b;
            text-transform: capitalize;
          }
          .Mui-selected {
            color: #e93434;
          }
        }
        .MuiTabs-indicator {
          background-color: #e93434;
        }
      }
    }
    @media (max-width: 580px) {
      width: 95%;
      .MuiTabs-root {
        .MuiTabs-scroller {
          .MuiTabs-flexContainer {
            .MuiButtonBase-root {
              font-size: 15px;
            }
          }
        }
      }
    }
  }
}
