.my-subscription-wrapper {
  .subscription-main-wrapper {
    max-width: 1110px;
    padding: 45px 15px;
    margin: 0 auto;
    @media screen and (max-width: 1199px) {
      padding: 30px 15px;
    }
    .place-order-button {
        height: 40px;
        background: #e93434;
        color: #fff;
        border-radius: 5px;
        min-width: 180px;
        font-size: 20px;
        // display: flex;
        align-items: center;
        justify-content: center;
        text-transform: capitalize;
        font-weight: 700;
        border: 1px solid #e93434;
        outline: none;
        cursor: pointer !important;
        @media screen and (max-width: 1600px) {
          font-size: 18px;
        }
        @media screen and (max-width: 1440px) {
          font-size: 16px;
        }
        @media screen and (max-width: 1199px) {
          height: 38px;
        }
      }
    .title {
      font-size: 40px;
      line-height: 52px;
      text-transform: capitalize;
      font-weight: 700;
      color: #606060;
      margin-bottom: 30px;
      @media screen and (max-width: 1600px) {
        font-size: 32px;
        line-height: 44px;
      }
      @media screen and (max-width: 1440px) {
        font-size: 30px;
        line-height: 42px;
      }
      @media screen and (max-width: 1199px) {
        font-size: 28px;
        line-height: 40px;
      }
      @media screen and (max-width: 767px) {
        font-size: 26px;
        line-height: 38px;
        margin-bottom: 20px;
      }
      @media screen and (max-width: 575px) {
        font-size: 24px;
        line-height: 36px;
      }
    }
    .subscription-main {
      h3 {
        font-size: 36px;
        line-height: 47px;
        font-weight: 700;
        color: #606060;
        margin-bottom: 15px;
        text-transform: capitalize;
        text-align: left;
        @media screen and (max-width: 1600px) {
          font-size: 30px;
          line-height: 40px;
        }
        @media screen and (max-width: 1440px) {
          font-size: 28px;
          line-height: 38px;
        }
        @media screen and (max-width: 1199px) {
          font-size: 26px;
          line-height: 36px;
        }
        @media screen and (max-width: 991px) {
          font-size: 24px;
          line-height: 34px;
        }
        @media screen and (max-width: 767px) {
          font-size: 22px;
          line-height: 32px;
          margin-bottom: 20px;
        }
        @media screen and (max-width: 575px) {
          font-size: 20px;
          line-height: 30px;
          margin-bottom: 15px;
        }
      }
      .subscription-list {
        .subscription-item {
          border: 1px solid #e93434;
          border-radius: 10px;
          background: #f7f7f7;
          padding: 15px 20px;
          margin-bottom: 10px;
          display: flex;
          justify-content: space-between;
          @media screen and (max-width: 767px) {
            padding: 15px;
            flex-direction: column;
          }
          &:last-child {
            margin-bottom: 0;
          }
          .title {
            font-size: 24px;
            line-height: 32px;
            color: #606060;
            margin-bottom: 10px;
            text-transform: capitalize;
            @media screen and (max-width: 1600px) {
              font-size: 22px;
              line-height: 30px;
            }
            @media screen and (max-width: 1199px) {
              font-size: 20px;
              line-height: 28px;
            }
            @media screen and (max-width: 767px) {
              font-size: 18px;
              line-height: 26px;
            }
          }
          .sub-title {
            font-size: 16px;
            line-height: 26px;
            color: #606060;
            margin-bottom: 15px;
            text-transform: capitalize;
            @media screen and (max-width: 1600px) {
              font-size: 15px;
              line-height: 25px;
            }
            @media screen and (max-width: 767px) {
              font-size: 14px;
              line-height: 24px;
            }
          }
          .purchase-date {
            font-size: 22px;
            line-height: 32px;
            color: #606060;
            font-weight: 700;
            margin-bottom: 20px;
            text-align: left;
            @media screen and (max-width: 1600px) {
              font-size: 22px;
              line-height: 30px;
            }
            @media screen and (max-width: 1199px) {
              font-size: 20px;
              line-height: 28px;
            }
            @media screen and (max-width: 767px) {
              font-size: 18px;
              line-height: 26px;
              margin-bottom: 15px;
            }
            @media screen and (max-width: 575px) {
              font-size: 16px;
              line-height: 24px;
            }
          }
          .amount {
            font-size: 40px;
            line-height: 52px;
            color: #606060;
            font-weight: 700;
            @media screen and (max-width: 1600px) {
              font-size: 35px;
              line-height: 45px;
            }
            @media screen and (max-width: 1199px) {
              font-size: 30px;
              line-height: 40px;
            }
            @media screen and (max-width: 767px) {
              font-size: 25px;
              line-height: 35px;
            }
            @media screen and (max-width: 575px) {
              font-size: 22px;
              line-height: 30px;
            }
          }
          ul {
            display: flex;
            align-items: center;
            gap: 35px;
            list-style-type: none;
            margin: 0;
            padding: 0;
            @media screen and (max-width: 1199px) {
              gap: 30px;
            }
            @media screen and (max-width: 767px) {
              gap: 20px;
            }
            li {
              display: flex;
              span {
                width: 20px;
                height: 20px;
                border-radius: 50%;
                border: 2px solid #e93434;
                background: #f7f7f7;
                cursor: pointer !important;
                @media screen and (max-width: 1199px) {
                  width: 18px;
                  height: 18px;
                }
                @media screen and (max-width: 767px) {
                  width: 15px;
                  height: 15px;
                }
              }
              &.active {
                span {
                  background: #e93434;
                }
              }
            }
          }
          .left {
            text-align: justify;
            margin-right: 20px;
          }
          .right {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-end;
            @media screen and (max-width: 767px) {
              align-items: flex-start;
              margin-top: 20px;
            }
          }
        }
      }
    }
  }
}
