.subscription {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  height: auto;
  .subscription-page-logo {
    position: absolute;
    top: 21px;
    left: 105px;
  }
  .subscription-page-title {
    color: #e93434;
    font-size: 40px;
    margin-top: 100px;
  }
  .subscription-page-cards {
    width: 85%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    hr {
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      color: #bdbdbd;
      margin: 10px 0px;
      width: 100%;
    }
    .subscription-page-card {
      position: relative;
      padding: 40px 40px 75px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      margin-top: 95px;
      p {
        text-align: left;
      }
      .subscription-card-title {
        font-size: 32px;
        font-weight: 700;
        margin-bottom: 12px;
      }
      .subscription-card-amount {
        font-size: 48px;
        font-weight: 700;
        margin-bottom: 19px;
      }
      .subscription-card-desc {
        font-family: "Merriweather", serif;
        line-height: 1.3;
      }
      .subscription-card-button {
        position: absolute;
        height: 80px;
        bottom: -40px;
        left: 50%;
        transform: translateX(-50%);
        font-size: 24px;
        background-color: #e93434;
        color: #f6f6f6;
        font-weight: 700;
        padding: 2px 34px;
        text-transform: capitalize;
      }
    }
    .subscription-page-left-card {
      flex: 0.45;
      background-color: #f6f6f6;
      color: #606060;
      position: relative;
      .subscription-page-left-card-icon-1 {
        position: absolute;
        bottom: -60px;
        left: -40px;
        z-index: -1;
      }
      .subscription-page-left-card-icon-2 {
        position: absolute;
        top: -70px;
        right: 20%;
        z-index: -1;
      }
    }
    .subscription-page-right-card {
      flex: 0.45;
      background-color: #292929;
      color: white;
      .subscription-page-left-card-icon-1 {
        position: absolute;
        top: -30px;
        right: -30px;
        z-index: -1;
      }
      .subscription-page-left-card-icon-3 {
        position: absolute;
        bottom: -60px;
        right: -75px;
        z-index: -1;
      }
      hr {
        border: 1px solid #bdbdbd;
      }
    }
  }
  @media (max-width: 910px) {
    .subscription-page-logo {
      top: 10px;
      left: 20px;
    }
    .subscription-page-title {
      margin-top: 120px;
      margin-bottom: 30px;
    }
    .subscription-page-cards {
      flex-direction: column;
      .subscription-page-card {
        margin-top: 0;
        margin-bottom: 95px;
        svg {
          display: none;
        }
      }
    }
  }
  @media (max-width: 595px) {
    .subscription-page-title {
      font-size: 20px;
    }
    .subscription-page-cards {
      .subscription-page-card {
        .subscription-card-title {
          font-size: 24px;
          margin-bottom: 12px;
        }
        .subscription-card-amount {
          font-size: 32px;
          font-weight: 700;
          margin-bottom: 19px;
        }
        .subscription-card-desc {
          font-size: 12px;
          font-family: "Merriweather", serif;
          line-height: 1.3;
        }
        .subscription-card-button {
          position: absolute;
          height: 47px;
          bottom: -20px;
          font-size: 20px;
          line-height: 1;
          padding: 2px 28px;
        }
      }
    }
  }
}
