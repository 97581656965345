.tray-filter {
  background-color: #f6f6f6;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  width: 100%;
  height: 150px;
  position: relative;
  margin-bottom: 130px;
  .tray-filter-title {
    font-weight: 700;
    padding-top: 1%;
    font-size: 36px;
    color: #e93434;
  }
  .tray-filter-main-container {
    width: 83%;
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translate(-50%, 50%);
    min-height: 125px;
    background: #ffffff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    padding: 30px 3%;
    height: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    .tray-filter-container {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;
      .tray-filter-select-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        .tray-filter-select-title {
          width: 100%;
          font-weight: 700;
          font-size: 20px;
          margin-bottom: 5px;
          color: #606060;
        }
        .tray-filter-select {
          width: 100%;
          fieldset {
            border: 1px solid #e93434;
          }
          .MuiSelect-select {
            text-align: left;
          }
        }
      }
      .try-filter-search {
        position: absolute;
        left: 50%;
        bottom: 0;
        text-transform: capitalize;
        background-color: #e93434;
        color: white;
        line-height: 1;
        padding: 10px 30px;
        font-weight: 700;
        font-size: 20px;
      }
    }
  }
}
.try-filter-mobile {
  display: flex;
  flex-direction: column;
  width: 100%;
  .tray-filter-mobile-title {
    margin: 20px 10px;
    font-weight: 700;
    font-size: 20px;
    color: #e93434;
  }
  .try-filter-mobile-select {
    margin: 10px 2%;
    > .MuiSvgIcon-root {
      display: none;
    }
    fieldset {
      border: 1px solid #e93434 !important;
      legend {
        display: none;
      }
    }
    button {
      text-transform: capitalize;
      background-color: #e93434;
      color: white;
      line-height: 1;
      padding: 10px 30px;
      font-weight: 700;
      font-size: 14px;
    }
  }
  .tray-filter-drawer {
    position: fixed;
    top: 0;
    z-index: 100;
    width: 100vw;
    height: 100vh;
    background-color: #ffffff;
    transition: all 300ms ease-in-out;
    .drawer-header {
      background-color: #f7f7f7;
      padding: 10px 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      h3 {
        font-weight: 700;
        font-size: 20px;
      }
      .drawer-button {
        text-transform: capitalize;
        background-color: #e93434;
        color: white;
        line-height: 1;
        padding: 10px 30px;
        font-weight: 700;
        font-size: 14px;
      }
    }
    .drawer-select-container {
      display: flex;
      flex-direction: column;
      padding: 10px 20px;
      .tray-filter-select-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        .tray-filter-select-title {
          font-weight: 700;
          font-size: 20px;
          margin-bottom: 10px;
          color: #606060;
        }
        .tray-filter-select {
          border-radius: 0;
          margin-bottom: 15px;
          width: 100%;
          fieldset {
            border: 1px solid #e93434;
          }
          .MuiSelect-select {
            text-align: left;
          }
        }
      }
    }
  }
}
