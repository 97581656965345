.homepage-image-gallery {
  background-image: url(../../assets/ImageGalleryBackground.png);
  background-repeat: no-repeat;
  background-size: cover;
  padding: 40px 0;
  .homepage-image-gallery-title {
    font-weight: 700;
    font-size: 48px;
    color: #e93434;
    margin-bottom: 20px;
  }
  .homepage-image-gallery-images {
    margin-bottom: 30px;
    .swiper-pagination {
      bottom: 0;
      .swiper-pagination-bullet {
        height: 10px;
        width: 10px;
        opacity: 1;
        background-color: #e93434;
        margin-bottom: 2px;
      }
      .swiper-pagination-bullet-active {
        border: 2px solid black;
      }
    }
    .swiper-wrapper {
      .swiper-slide {
        .homepage-image-gallery-image-container {
          height: auto;
          width: 262px;
          border: 2px solid #e93434;
          border-radius: 15px 0px;
          overflow: hidden;
          margin: 0 auto;
          margin-bottom: 30px;
          .homepage-image-gallery-image {
            width: 100%;
            height: 100%;
            object-fit: fill;
          }
        }
      }
    }
  }
  .homepage-image-gallery-btn {
    line-height: 1;
    background-color: #e93434;
    font-size: 24px;
    color: white;
    font-weight: 700;
    text-transform: capitalize;
    padding: 10px 26px;
    &:hover {
      background-color: rgba(233, 52, 52, 0.7);
    }
  }
  @media (max-width: 686px) {
    .homepage-image-gallery-images {
      .swiper-wrapper {
        .swiper-slide {
          .homepage-image-gallery-image-container {
            height: auto;
            width: 204px;
            border: 0px;
            box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
            .homepage-image-gallery-image {
              width: 100%;
              height: 100%;
              object-fit: fill;
            }
          }
        }
      }
    }
    .homepage-image-gallery-btn {
      font-size: 14px;
      padding: 10px 18px;
    }
  }
}
