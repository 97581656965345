.forgot-password-page {
  font-family: "Lato", sans-serif !important;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  .forgot-password-page-logo {
    position: absolute;
    top: 21px;
    left: 105px;
    z-index: 1;
    @media (max-width: 1000px) {
      top: 10px;
      left: 20px;
    }
  }
  .forgot-password-page-container {
    display: flex;
    width: 100%;
    .forgot-password-page-leftbox {
      min-width: 45%;
      max-width: 45%;
      overflow: hidden;
      position: relative;
      .forgot-password-page-photo {
        width: 100%;
        height: 100%;
      }
    }
    .forgot-password-page-rightbox {
      width: 55%;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      padding: 2%;
      &::before {
        content: "";
        height: 70%;
        width: 8%;
        position: absolute;
        top: 0;
        left: -7%;
        background-color: white;
        top: 50%;
        transform: translate(0, -50%);
        border-radius: 90px 0 0 90px;
      }
      .forgot-password-page-right-title {
        color: #e93434;
        font-size: 48px;
        margin: 10px 0px 20px 0;
        text-align: left;
      }
      .forgot-password-page-right-subtitle {
        font-family: "Merriweather", serif;
        font-size: 16px;
        text-align: left;
        color: #606060;
        font-weight: 400;
        margin-bottom: 20px;
      }
      .forgot-password-page-right-email-container {
        width: 100%;
        .forgot-password-page-right-email-label {
          color: #606060;
          font-size: 20px;
          text-align: left;
          margin: 10px 0;
        }
      }
      .forgot-password-page-right-button {
        margin: 30px 0 30px auto;
        text-transform: capitalize;
        background-color: #e93434;
        color: #f6f6f6;
        padding: 2px 34px;
        font-weight: 600;
        font-size: 36px;
      }
      .forgot-password-page-remember-password {
        margin: 10px 0px;
        width: 100%;
        font-size: 20px;
        font-weight: 700;
        text-align: left;
        color: #606060;
        a {
          text-decoration: none;
          font-weight: 700;
          color: #e93434;
        }
      }

      .MuiTextField-root {
        width: 100%;
        .MuiOutlinedInput-root {
          border-radius: 7px;
          background-color: #f6f6f6;
        }
      }
    }
  }
  @media (max-width: 830px) {
    .forgot-password-page-container {
      flex-direction: column-reverse;
      justify-content: center;
      align-items: center;
      margin-top: 100px;
      .forgot-password-page-leftbox {
        display: none;
        height: 100%;
        display: flex;
        align-items: flex-end;
        .forgot-password-page-photo {
          display: none;
        }
      }
      .forgot-password-page-rightbox {
        width: 95%;
        padding: 0;
        &::before {
          display: none;
        }
        .forgot-password-page-right-title {
          font-size: 32px;
        }
        .forgot-password-page-right-subtitle {
          font-size: 12px;
          font-family: "Merriweather", serif;
          font-weight: 400;
        }
        .forgot-password-page-right-email-container {
          .forgot-password-page-right-email-label {
            font-size: 20px;
          }
        }
        .forgot-password-page-right-button {
          margin: 20px auto;
          font-size: 20px;
        }
        .forgot-password-page-remember-password {
          text-align: center;
          font-size: 14px;
        }
      }
    }
  }
}
