.tiffin-ordering {
  padding-top: 120px;
  @media (max-width: 790px) {
    padding-top: 70px;
  }
  .loading-backdrop {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.80);
    z-index: 1500;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .image-container {
    width: 100%;
    position: relative;
    img {
      width: 100%;
      object-fit: cover;
    }
    .image-text {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%);
      width: 80%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      @media (max-width: 650px) {
        display: none;
      }
      h3 {
        font-weight: 700;
        font-weight: 700;
        font-size: 32px;
        color: #e93434;
      }
      h1 {
        font-weight: 700;
        color: #f6f6f6;
        font-size: 40px;
      }
      button {
        background-color: #e93434;
        line-height: 1;
        text-transform: capitalize;
        color: #f6f6f6;
        padding: 10px 20px;
        font-size: 20px;
        font-weight: 700;
        margin-top: 10px;
      }
    }
  }
  .tiffin-ordering-container {
    padding: 40px 5%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .tiffin-ordering-title {
      font-size: 38px;
      font-weight: 800;
      color: #000000;
      margin-bottom: 20px;
    }
  }
}

.tiffin-ordering-card {
  display: flex;
  flex-direction: row;
  margin: 20px 0;
  &:nth-of-type(even) {
    flex-direction: row-reverse;
  }
  .image-section {
    width: 50%;
    margin-right: 20px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .details-section {
    width: 50%;
    text-align: left;
    color: #000000;
    margin-right: 20px;
    text-align: justify;
  text-justify: inter-word;
    .detail-title {
      font-size: 40px;
      font-weight: 700;
      margin-bottom: 15px;
      color: #e93434;
    }
    .detail-deliver-title {
      font-weight: bold;
      font-size: 20px;
      margin-bottom: 15px;
    }
    .detail-deliver-address {
      margin-bottom: 15px;
      line-height: 1.5;
      font-weight: 400;
      font-size: 22px;
      font-family: "Merriweather", serif;
    }
    button {
      background: #e93434;
      line-height: 1;
      text-transform: capitalize;
      color: #f6f6f6;
      font-weight: 700;
      font-size: 24px;
      padding: 10px 20px;
      float: right;
      margin: 0 20px 0 0;
      a {
        color: #f6f6f6;
        text-decoration: none;
      }
    }
  }
  @media (max-width: 920px) {
    .details-section {
      .detail-title {
        font-size: 25px;
        margin-bottom: 10px;
      }
      .detail-deliver-title {
        font-size: 12px;
        margin-bottom: 10px;
      }
      .detail-deliver-address {
        margin-bottom: 10px;
        font-size: 15px;
      }
      button {
        font-size: 15px;
      }
    }
  }
  @media (max-width: 700px) {
    flex-direction: column !important;
    .image-section {
      width: 100%;
      img {
        width: 100%;
      }
    }
    .details-section {
      width: 100%;
      margin-top: 10px;
      button {
        margin: 0;
      }
    }
  }
}
