.tray-menu {
  padding-top: 120px;

  @media (max-width: 790px) {
    padding-top: 70px;
  }

  .tray-menu-container {
    .tray-menu-main-image {
      width: 100%;
      object-fit: cover;
    }
  }
  button {
    text-decoration: none;
    cursor: pointer;
    display: inline-block;
    padding: 8px 16px;
  }
  
  button:hover {
    background-color: #ddd;
    color: black;
  }
  
  .product-card-add-to-cart {
    flex: 1;
    border: none;
    background: none;
    background-color: #e93434;
    color: white;
    text-transform: capitalize;
    font-weight: 400;
    font-size: 16px;
  }
  
  .round {
    border-radius: 50%;
  }
}


.tray-menu-products {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  margin: 1% 3%;
  margin-bottom: 30px;
  position: relative;

  @media (max-width: 830px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 600px) {
    grid-template-columns: repeat(1, 1fr);

    .tray-menu-notification {
      position: sticky;
      bottom: 10px;
      width: auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px 20px;
      background: #fba4a4;
      border: 5px solid #e93434;
      border-radius: 37.5px;

      .tray-menu-price {
        display: flex;
        align-items: center;

        span {
          margin-left: 10px;
          font-weight: 700;
          font-size: 20px;
        }
      }

      .tray-menu-view-cart {
        font-weight: 700;
        font-size: 20px;
      }
    }
  }
}

.next-prev-btn{
  display: inline;
}

.arrow-prev{
  cursor: pointer;
    position: relative;
    display: inline-block;
    margin: 20px 0 15px 10px;
    width: 40px;
    height: 40px;
    border: solid 6px #e93434;
    border-radius: 100%;
    z-index: 1;
    transition: all 0.2s linear;
    transform: scale(-1);
  &:before, &:after{
    content:"";
    position: absolute;
    width:35%;
    height: 10%;
    top:41%;
    left:55%;
    background: #e93434;
    z-index: 2;
    transform: translate(-50%, -50%) rotate(45deg);
    transition: all .2s linear;
  }
  &:after{
    z-index: 3;
    top:59%;
    left:55%;
    transform: translate(-50%, -50%) rotate(-45deg);
  }
  @media (min-width: 790px) {
    &:hover{
      border: solid 8px #be2323;
      &:after, &:before{
        background: #be2323;
      }
    }
  }
  &:active{
    border: solid 8px #111;
    &:after, &:before{
      background: #111;
    }
  }
}
.arrow-prev-disabled {
  position: relative;
  display: inline-block;
  margin: 20px 0 15px 10px;
  width: 40px;
  height: 40px;
  border: solid 6px #999;
  border-radius: 100%;
  z-index: 1;
  transition: all 0.2s linear;
  transform: scale(-1);
  &:before, &:after{
    content:"";
    position: absolute;
    width:35%;
    height: 10%;
    top:41%;
    left:55%;
    background: #999;
    z-index: 2;
    transform: translate(-50%, -50%) rotate(45deg);
    transition: all .2s linear;
  }
  &:after{
    z-index: 3;
    top:59%;
    left:55%;
    transform: translate(-50%, -50%) rotate(-45deg);
  }
}

.arrow-next{
  cursor: pointer;
  position: relative;
  display: inline-block;
  margin: 20px 0 15px 10px;
  width: 40px;
  height: 40px;
  border: solid 6px #e93434;
  border-radius: 100%;
  z-index: 1;
  transition: all 0.2s linear;
  &:before, &:after{
    content:"";
    position: absolute;
    width:35%;
    height: 10%;
    top:41%;
    left:55%;
    background: #e93434;
    z-index: 2;
    transform: translate(-50%, -50%) rotate(45deg);
    transition: all .2s linear;
  }
  &:after{
    z-index: 3;
    top:59%;
    left:55%;
    transform: translate(-50%, -50%) rotate(-45deg);
  }
  @media (min-width: 900px) {
    &:hover {
      border: solid 8px #be2323;
      &:after, &:before{
        background: #be2323;
      }
    }
  }
  &:active{
    border: solid 8px #111;
    &:after, &:before{
      background: #111;
    }
  }
}
.arrow-next-disabled {
  position: relative;
  display: inline-block;
  margin: 20px 0 15px 10px;
  width: 40px;
  height: 40px;
  border: solid 6px #999;
  border-radius: 100%;
  z-index: 1;
  transition: all 0.2s linear;
  &:before, &:after{
    content:"";
    position: absolute;
    width:35%;
    height: 10%;
    top:41%;
    left:55%;
    background: #999;
    z-index: 2;
    transform: translate(-50%, -50%) rotate(45deg);
    transition: all .2s linear;
  }
  &:after{
    z-index: 3;
    top:59%;
    left:55%;
    transform: translate(-50%, -50%) rotate(-45deg);
  }
}

.loading-backdrop {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 1500;
  display: flex;
  justify-content: center;
  align-items: center;
}