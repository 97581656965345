.product-single-page {
  padding-top: 120px;
  @media (max-width: 790px) {
    padding-top: 70px;
  }
  width: 100%;
  .loading-backdrop {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 1500;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .product-single-page-image-container {
    width: 100%;
    position: relative;
    img {
      width: 100%;
      object-fit: cover;
    }
    .main-image-text {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%);
      width: 80%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      h3 {
        font-weight: 700;
        font-weight: 700;
        font-size: 32px;
        color: #e93434;
      }
      h1 {
        font-weight: 700;
        color: #f6f6f6;
        font-size: 40px;
      }
    }
  }
  .product-single-page-container {
    padding: 40px 5%;
    .product-single-page-accordion-container {
      margin-top: 20px;
      .MuiAccordion-root {
        margin: 20px 0;
        border-radius: 0;
        border: 0px;
        box-shadow: none;
        &:before {
          display: none;
        }
        .MuiAccordionSummary-root {
          background-color: #f6f6f6;
          border-radius: 10px;
          border: 1px solid #e93434;
          min-height: 0;
          .MuiAccordionSummary-content {
            align-items: center;
            justify-content: space-between;
            margin: 12px 0;
            p {
              font-weight: 700;
              font-size: 24px;
              color: #606060;
              text-align: left;
            }
            .chip-container {
              .MuiChip-root {
                margin: 0 10px 5px 0;
                background-color: #e93434;
                color: #f6f6f6;
                font-weight: 700;
                font-size: 20px;
              }
            }
          }
        }
        .MuiCollapse-root {
          .accordion-details {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            .accordion-details-container {
              padding: 10px 0;
            }
          }
          .accordion-detail-1 {
            display: grid;
            grid-template-columns: repeat(1, 1fr);
            margin-top: 10px;
            .free-only-one {
              display: grid;
              grid-template-columns: repeat(3, 1fr);
              justify-items: center;
              .radio-btn {
                .MuiButtonBase-root {
                  .MuiTouchRipple-root {
                    display: none;
                  }
                  span {
                    &::before {
                      border: 1px solid #e93434;
                    }
                    &::after {
                      background: #e93434;
                    }
                  }
                  &:hover {
                    background: none;
                  }
                }
                .MuiTypography-root {
                  font-weight: 400;
                  font-size: 16px;
                  font-family: "Merriweather", serif;
                  padding-left: 20px;
                }
              }
            }
          }
          // #accordion-content {
          //   display: grid;
          //   grid-template-columns: repeat(2, 1fr);
          // }
        }
        .accordion-details-container {
          display: grid;
          grid-template-columns: repeat(2, 1fr);

          .product-detail-cart {
            display: flex;
            width: 100%;
            .product-detail-numbers {
              background-color: #f6f6f6;
              border: 1px solid #e93434;
              border-radius: 5px;
              padding: 0px 10px;
              display: flex;
              align-items: center;
              justify-content: space-around;
              margin-right: 10px;
              div {
                margin: 0 10px;
              }
              .product-btn {
                font-size: 20px;
                cursor: pointer;
                font-weight: 700;
                padding: 3px;
                border-radius: 50%;
                border: 1px solid #e93434;
                width: 20px;
                height: 20px;
                margin: 0 5px;
              }
              .product-number {
                font-size: 20px;
                font-weight: 700;
              }
            }
            .product-detail-add-to-cart {
              background: #e93434;
              border-radius: 5px;
              font-weight: 700;
              font-size: 20px;
              line-height: 0;
              text-transform: capitalize;
              color: white;
              width: 100%;
              max-width: 250px;
            }
          }
          .MuiCheckbox-root {
            color: #e93434;
          }
          .MuiFormControlLabel-label {
            font-weight: 400;
            font-size: 16px;
            font-family: "Merriweather", serif;
          }
        }
      }
    }
    .add-to-cart-btn {
      line-height: 1;
      background-color: #e93434;
      color: #f7f7f7;
      font-weight: 700;
      text-transform: capitalize;
      font-size: 32px;
      padding: 10px 30px;
      @media (max-width: 600px) {
        font-size: 20px;
      }
    }
  }
  @media (max-width: 760px) {
    .product-single-page-container {
      .product-single-page-accordion-container {
        .MuiAccordion-root {
          .accordion-details-container {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
          }
        }
      }
    }
  }
  @media (max-width: 600px) {
    .product-single-page-container {
      .product-single-page-accordion-container {
        .MuiAccordion-root {
          .MuiAccordionSummary-root {
            .MuiAccordionSummary-content {
              p {
                font-size: 15px;
              }
              .chip-container {
                .MuiChip-root {
                  font-size: 12px;
                }
              }
            }
          }
          .accordion-details-container {
            .MuiFormControlLabel-label {
              font-size: 15px;
            }
          }
        }
      }
    }
  }
  @media (max-width: 540px) {
    .product-single-page-image-container {
      .main-image-text {
        display: none;
      }
    }
    .product-single-page-container {
      .product-single-page-accordion-container {
        .MuiAccordion-root {
          .accordion-details-container {
            display: grid;
            grid-template-columns: repeat(1, 1fr);
          }
        }
      }
    }
  }
}
