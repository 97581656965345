.coming-soon-page-wrapper {
  position: relative;
  background: #292929;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: calc(100% - 20px);
  background: url(../../assets/coming-bg.png) no-repeat;
  background-size: cover;
  background-position: 100%;
  padding: 0 10px;
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
  }
  .content-block {
    background: rgba(255, 255, 255, 0.5);
    color: #292929;
    position: relative;
    z-index: 1;
    padding: 40px 40px;
    border-radius: 3px;
    max-width: 500px;
    width: 100%;
    min-height: 40%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @media screen and (max-width: 575px){
        padding: 40px 25px;
    }

    .title {
      font-size: 40px;
      color: #fff;
      position: relative;
      letter-spacing: 5px;
      margin-bottom: 40px;
      text-shadow: 0 0 20px black;
      @media screen and (max-width: 575px){
        font-size: 30px;
        margin-bottom: 30px;
      }
    }
    p, span{
        color: #fff;
        font-size: 18px;
        font-weight: 700;
        letter-spacing: 1px;
        margin-bottom: 20px;
        @media screen and (max-width: 575px){
            font-size: 16px;
        }
    }
    span{
        text-transform: capitalize;
        margin: 0;
    }
    .field-area{
        display: flex;
        align-items: center;
        margin: 30px 0 0;
        @media screen and (max-width: 575px){
            flex-direction: column;
        }
        input{
            height: 40px;
            background: #fff;
            border-radius: 3px;
            color: #292929;
            padding: 0 12px;
            outline: none;
            border: none;
            font-size: 15px;
            flex: 1;
            @media screen and (max-width: 575px){
                flex: auto;
                width: 100%;
                margin-bottom: 20px;
                height: 38px;
            }
            &::placeholder{
                color: #ccc;
            }
        }
        button{
            height: 40px;
            border: 0;
            outline: none;
            border-radius: 3px;
            background: #fff;
            color: #292929;
            margin-left: 10px;
            width: auto;
            padding: 0 20px;
            font-weight: 700;
            text-transform: capitalize;
            font-size: 14px;
            cursor: pointer !important;
            @media screen and (max-width: 575px){
                height: 38px;
            }
        }
    }
    
  }
}
