.cart-banner {
  // height: 640px;
  // @media screen and (max-width: 1440px) {
  //   height: 540px;
  // }
  // @media screen and (max-width: 1199px) {
  //   height: 480px;
  // }
  // @media screen and (max-width: 991px) {
  //   height: 400px;
  // }
  // @media screen and (max-width: 767px) {
  //   height: 350px;
  // }
  // @media screen and (max-width: 575px) {
  //   height: 270px;
  // }
  // @media screen and (max-width: 480px) {
  //   height: 230px;
  // }
  img {
    width: 100%;
    object-fit: cover;
  }
}
