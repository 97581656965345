.App {
  text-align: center;
  font-family: "Lato", sans-serif !important;
}

// ::-webkit-scrollbar {
//   display: none;
// }

h1,
h2,
h3,
h4,
h5,
h6,
p,
span,
a,
div {
  letter-spacing: 0.5px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}
