.images-grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  .image-container {
    img {
      width: 100%;
      object-fit: cover;
    }
  }
  @media (max-width: 700px) {
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  }
}
