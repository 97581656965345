// .page-not-found{
//   width: 100%;
//   height: 100vh;
//   // background:  linear-gradient(90deg, rgba(65,0,0,1) 0%, rgba(255,0,0,1) 100%);
//   background: #ccc;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   flex-direction: column;
//   position: relative;
//   img{
//     max-width: 700px;
//     width: 100%;
//   }
//   .title{
//     font-size: 30px;
//     line-height: 40px;
//     text-transform: uppercase;
//   }
// }
.cont_principal {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: #292929;
}
.cont_error {
  // position: absolute;
  //   width: 100%;
  //   height: 300px;
  //   top: 50%;
  //   margin-top:-150px;
  position: absolute;
  width: calc(100% - 30px);
  height: auto;
  padding: 0 15px;
  top: 50%;
  /* margin-top: -150px; */
  transform: translateY(-50%);
}

.cont_error > h1 {
  font-family: "Lato", sans-serif;
  font-weight: 400;
  font-size: 100px;
  // color:#E93434;
  color: #fff;
  position: relative;
  left: -100%;
  transition: all 0.5s;
  position: relative;
  z-index: 10;
  font-weight: 700;
  letter-spacing: 15px;
  @media screen and (max-width: 767px) {
    font-size: 50px;
  }
}
.button {
  height: 40px;
  background: #e93434;
  color: #fff;
  border-radius: 4px;
  text-transform: uppercase;
  border: none;
  outline: none;
  padding: 0 30px;
  font-weight: 700;
  letter-spacing: 1px;
  margin-top: 50px;
  cursor: pointer !important;
  position: relative;
  z-index: 10;
  font-size: 14px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  @media screen and (max-width: 767px) {
    margin-top: 30px;
    font-size: 12px;
    padding: 0 20px;
    height: 35px;
  }
}

.cont_error > p {
  font-family: "Lato", sans-serif;
  font-weight: 300;
  font-size: 24px;
  letter-spacing: 5px;
  // color:#9294AE;
  color: #fff;
  position: relative;
  left: 100%;
  transition: all 0.5s;
  transition-delay: 0.5s;
  -webkit-transition: all 0.5s;
  -webkit-transition-delay: 0.5s;
  position: relative;
  z-index: 10;
  @media screen and (max-width: 767px) {
    font-size: 16px;
  }
}

.cont_aura_1 {
  position: absolute;
  width: 300px;
  height: 120%;
  top: 25px;
  right: -340px;
  // background-color:#E93434;
  background-color: #fff;
  box-shadow: 0px 0px 60px 20px rgba(233, 52, 52, 0);
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  @media screen and (max-width: 767px) {
    height: 100%;
    top: 45px;
  }
  @media screen and (max-width: 575px) {
    top: 62px;
  }
}

.cont_aura_2 {
  position: absolute;
  width: 100%;
  height: 300px;
  right: -10%;
  bottom: -301px;
  //  background-color: #E93434;
  background-color: #fff;
  // box-shadow: 0px 0px 60px 10px rgba(233, 52, 52, 0.2),0px 0px  20px  0px  rgba(0,0,0,0.1);
  box-shadow: 0px 0px 60px 10px rgba(233, 52, 52, 0),
    0px 0px 20px 0px rgba(0, 0, 0, 0.1);
  z-index: 5;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  @media screen and (max-width: 991px) {
    bottom: -215px;
  }
  @media screen and (max-width: 767px) {
    right: -92px;
    bottom: -250px;
  }
}

.cont_error_active > .cont_error > h1 {
  left: 0%;
}
.cont_error_active > .cont_error > p {
  left: 0%;
}

.cont_error_active > .cont_aura_2 {
  animation-name: animation_error_2;
  animation-duration: 4s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  transform: rotate(-20deg);
}
.cont_error_active > .cont_aura_1 {
  transform: rotate(20deg);
  right: -170px;
  animation-name: animation_error_1;
  animation-duration: 4s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  @media screen and (max-width: 991px) {
    right: -230px;
  }
  @media screen and (max-width: 767px) {
    right: -325px;
  }
}

@-webkit-keyframes animation_error_1 {
  from {
    -webkit-transform: rotate(20deg);
    transform: rotate(20deg);
  }
  to {
    -webkit-transform: rotate(25deg);
    transform: rotate(25deg);
  }
}
@-o-keyframes animation_error_1 {
  from {
    -webkit-transform: rotate(20deg);
    transform: rotate(20deg);
  }
  to {
    -webkit-transform: rotate(25deg);
    transform: rotate(25deg);
  }
}
@-moz-keyframes animation_error_1 {
  from {
    -webkit-transform: rotate(20deg);
    transform: rotate(20deg);
  }
  to {
    -webkit-transform: rotate(25deg);
    transform: rotate(25deg);
  }
}
@keyframes animation_error_1 {
  from {
    -webkit-transform: rotate(20deg);
    transform: rotate(20deg);
  }
  to {
    -webkit-transform: rotate(25deg);
    transform: rotate(25deg);
  }
}

@-webkit-keyframes animation_error_2 {
  from {
    -webkit-transform: rotate(-15deg);
    transform: rotate(-15deg);
  }
  to {
    -webkit-transform: rotate(-20deg);
    transform: rotate(-20deg);
  }
}
@-o-keyframes animation_error_2 {
  from {
    -webkit-transform: rotate(-15deg);
    transform: rotate(-15deg);
  }
  to {
    -webkit-transform: rotate(-20deg);
    transform: rotate(-20deg);
  }
}

@-moz-keyframes animation_error_2 {
  from {
    -webkit-transform: rotate(-15deg);
    transform: rotate(-15deg);
  }
  to {
    -webkit-transform: rotate(-20deg);
    transform: rotate(-20deg);
  }
}
@keyframes animation_error_2 {
  from {
    -webkit-transform: rotate(-15deg);
    transform: rotate(-15deg);
  }
  to {
    -webkit-transform: rotate(-20deg);
    transform: rotate(-20deg);
  }
}
