.row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}
.col-6 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}
.homepage-dishes {
  padding: 10px 0 30px;
  .homepage-dishes-title {
    color: #f6f6f6;
  }
  .homepage-dishes-cards {
    width: 90%;
    margin: 20px auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .homepage-dishes-card-container {
      display: flex;
      justify-content: space-evenly;
      align-items: flex-start;
      margin-bottom: 20px;
      .card {
        flex: 0.47;
      }
    }
  }
  .homepage-dishes-order-btn {
    font-family: "Lato", sans-serif !important;
    line-height: 1;
    font-weight: 700;
    padding: 15px 40px;
    background-color: #e93434;
    color: white;
    border-radius: 69px;
    font-size: 24px;
    &:hover {
      background-color: rgba(233, 52, 52, 0.7);
    }
  }
  @media (max-width: 750px) {
    .homepage-dishes-title {
      font-size: 20px;
      span {
        display: block;
      }
    }
    .homepage-dishes-order-btn {
      // display: none;
      font-size: 16px;
      margin-top: 20px;
    }
    .swiper {
      .swiper-pagination {
        bottom: 0px;
        .swiper-pagination-bullet {
          height: 10px;
          width: 10px;
          opacity: 1;
          background-color: #e93434;
          margin-bottom: 2px;
        }
        .swiper-pagination-bullet-active {
          border: 2px solid #fff;
        }
      }
    }
  }
}
