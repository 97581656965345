.signin-page {
  font-family: "Lato", sans-serif !important;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  .sign-page-logo {
    position: absolute;
    top: 21px;
    left: 105px;
    z-index: 1;
    @media (max-width: 1000px) {
      top: 10px;
      left: 20px;
    }
  }
  .signin-page-container {
    display: flex;
    width: 100%;
    .signin-page-leftbox {
      min-width: 45%;
      max-width: 45%;
      overflow: hidden;
      position: relative;
      background-color: #292929;
      .signin-page-left-center {
        width: 70%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: white;
        .signin-page-left-title {
          font-size: 48px;
        }
        .signin-page-left-subtitle {
          font-size: 16px;
          font-family: "Merriweather", serif;
          font-weight: 400;
          margin: 25px 0px;
        }
        .signin-page-left-button {
          padding: 2px 34px;
          background-color: #f6f6f6;
          color: #e93434;
          text-transform: capitalize;
          font-weight: 600;
          font-size: 26px;
        }
      }
    }
    .signin-page-rightbox {
      width: 55%;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      padding: 2%;
      &::before {
        content: "";
        height: 70%;
        width: 8%;
        position: absolute;
        top: 0;
        left: -7%;
        background-color: white;
        top: 50%;
        transform: translate(0, -50%);
        border-radius: 90px 0 0 90px;
      }
      .signin-page-right-title {
        color: #e93434;
        font-size: 48px;
        margin: 10px 0px 20px 0;
      }
      .signin-page-right-email-container,
      .signin-page-right-password-container {
        width: 100%;
        .signin-page-right-email-label,
        .signin-page-right-password-label {
          color: #606060;
          font-size: 20px;
          text-align: left;
          margin: 10px 0;
        }
      }
      .signin-page-rememberme-container {
        margin: 10px 0px;
        width: 100%;
        font-size: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        label {
          span {
            font-size: 20px;
            font-weight: 700;
            color: #606060;
          }
        }
        a {
          text-decoration: none;
          font-weight: 700;
          color: #606060;
        }
      }
      .signin-page-right-button {
        margin-left: auto;
        margin-top: 15px;
        text-transform: capitalize;
        background-color: #e93434;
        color: #f6f6f6;
        padding: 2px 34px;
        font-weight: 600;
        font-size: 36px;
      }
      .MuiTextField-root {
        width: 100%;
        .MuiOutlinedInput-root {
          border-radius: 7px;
          background-color: #f6f6f6;
        }
      }
    }
  }
  @media (max-width: 830px) {
    .signin-page-container {
      flex-direction: column-reverse;
      justify-content: center;
      align-items: center;
      margin-top: 100px;
      .signin-page-leftbox {
        width: 95%;
        max-width: 100%;
        height: 100%;
        background-color: transparent;
        display: flex;
        padding: 10px 0;
        align-items: flex-end;
        .signin-page-left-center {
          width: 100%;
          position: relative;
          top: 0;
          left: 0;
          transform: translate(0%, 0%);
          color: #292929;
          .signin-page-left-title {
            font-size: 20px;
          }
          .signin-page-left-subtitle {
            font-size: 12px;
            margin: 13px 0;
          }
          .signin-page-left-button {
            padding: 2px 34px;
            border: 2px solid #e93434;
            text-transform: capitalize;
            font-size: 20px;
          }
        }
      }
      .signin-page-rightbox {
        width: 95%;
        padding: 0;
        &::before {
          display: none;
        }
        .signin-page-right-title {
          font-size: 32px;
        }
        .signin-page-right-email-container,
        .signin-page-right-password-container {
          .signin-page-right-email-label,
          .signin-page-right-password-label {
            font-size: 20px;
          }
        }
        .signin-page-rememberme-container {
          label {
            span {
              font-size: 14px;
            }
          }
          a {
            font-size: 14px;
          }
        }
        .signin-page-right-button {
          margin: 0 auto;
          font-size: 20px;
        }
      }
    }
  }
}
