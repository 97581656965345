.product-card {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow: hidden;
  background-color: #f7f7f7;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  .product-card-image-container {
    width: 100%;

    .product-card-image {
      object-fit: contain;
      width: 200px;
      height: 100px;
    }
  }

  .product-card-details {
    padding: 3%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: -webkit-fill-available;

    .product-card-title {
      font-weight: 700;
      font-size: 24px;
      color: #606060;
      text-align: left;
    }

    .product-card-price {
      font-family: "Merriweather", serif;
      font-weight: 400;
      font-size: 24px;
      color: #606060;
      margin-top: 10px;
    }

    .product-card-numbers {
      border: 1px solid #e93434;
      background: #fff;
      border-radius: 5px;
      padding: 10px;
      margin-top: 10px;
      display: flex;
      align-items: center;
      justify-content: space-around;
      margin-right: 10px;

      div {
        margin: 0 10px;
      }

      .product-btn {
        font-size: 20px;
        cursor: pointer;
        font-weight: 700;
        line-height: 0.5;
        padding: 6px;
        border-radius: 50%;
        border: 1px solid #e93434;
      }

      .product-number {
        font-size: 20px;
        font-weight: 700;
      }
    }

    .product-card-select {
      width: 100%;
      margin-top: 10px;

      .MuiSelect-select {
        text-align: left;
      }

      fieldset {
        border: 1px solid #e93434 !important;
        top: 0;

        legend {
          display: none;
        }
      }
    }

    .product-card-btns {
      margin-top: 10px;
      display: flex;
      width: 100%;

      .product-card-wishlist {
        background-color: #e93434;
        margin-right: 10px;
        line-height: 0;
        min-width: 0;
        min-height: 0;
        padding: 0 10px;
      }

      .product-card-add-to-cart {
        flex: 1;
        width: 100%;
        background-color: #e93434;
        color: white;
        text-transform: capitalize;
        font-weight: 400;
        font-size: 16px;
      }
    }
  }

  @media (max-width: 600px) {
    flex-direction: row;

    .product-card-image-container {
      width: 100%;
      height: 100%;
      max-width: 150px;

      .product-card-image {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }

    .product-card-details {
      position: relative;

      .product-card-title {
        width: 85%;
        font-size: 14px;
      }

      .product-card-price {
        font-size: 24px;
      }

      .product-card-select {
        font-size: 12px;
      }

      .product-card-btns {
        .product-card-wishlist {
          position: absolute;
          top: 0;
          right: 0;
          margin-right: 0;
          width: min-content;
          padding: 5px;
          margin: 5px;

          svg {
            font-size: 15px;
          }
        }

        .product-card-add-to-cart {
          font-size: 12px;
          font-family: "Merriweather", serif;
        }
      }
    }
  }
}