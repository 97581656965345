.card-cities {
  width: 95%;
  border: 5px solid #292929;
  border-radius: 40px;
  margin-top: 55px;
  overflow: visible;
  position: relative;
  margin-bottom: 40px;
  background-color: #f6f6f6;
  color: white;
  .card-title-cities {
    position: absolute;
    top: -53px;
    left: 50%;
    height: 83px;
    max-width: 70%;
    width: 100%;
    transform: translate(-50%);
    background-color: #292929;
    border-radius: 53px;
    padding: 12px 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    h1 {
      line-height: 1;
      font-size: 32px;
      font-weight: 700;
    }
  }
  .card-container {
    display: flex;
    flex-wrap: wrap;
    padding: 60px 0 20px 10px;
    .MuiChip-root {
      border: 1px solid #292929;
      margin: 5px;
      span {
        font-family: "Lato", sans-serif !important;
        font-weight: 700;
        font-size: 20px;
        color: #292929;
      }
    }
  }
  @media (max-width: 700px) {
    .card-title-cities {
      h1 {
        font-size: 20px;
      }
    }
    .card-container {
      .MuiChip-root {
        span {
          font-size: 14px;
        }
      }
    }
  }
  @media (max-width: 450px) {
    width: 97%;
  }
}
