.splash {
  // padding-top: 120px;
  @media (max-width: 790px) {
    // padding-top: 70px;
  }
  max-width: 100vw;
  .splash-main-image {
    position: relative;
    height: 600px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .d-none {
      display: none;
    }
    .splash-image-details {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%);
      width: 80%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      .splash-image-headings {
        flex: 1;
        text-align: left;
        h4 {
          font-weight: 700;
          font-size: 32px;
          color: #e93434;
        }
        h3 {
          font-weight: 700;
          font-size: 36px;
          color: #f7f7f7;
        }
      }
      .splash-image-cards {
        flex: 1;
        .splash-image-card {
          width: 65%;
          background-color: #ffffff;
          border: 5px solid #e93434;
          margin: 19px 0px;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          padding: 10px 20px;
          position: relative;
          .splash-image-card-price {
            position: absolute;
            right: 0;
            top: 0;
            font-weight: 700;
            font-size: 32px;
            background: #e93434;
            color: #fff;
            padding: 12px;
          }
          h3 {
            font-weight: 700;
            font-size: 40px;
            width: 75%;
          }
          h5 {
            font-family: "Merriweather", serif;
            font-weight: 400;
            font-size: 28px;
          }
          p {
            margin: 0;
            padding: 0;
            text-align: left;
            font-weight: 700;
            font-size: 24px;
          }
          h3,
          h5,
          p {
            margin-bottom: 5px;
            text-align: left;
          }
          .splash-image-card-warning {
            color: #e50000;
            font-weight: 700;
            font-size: 18px;
          }
        }
        .card-right {
          margin-left: auto;
        }
      }
    }
    @media (max-width: 500px) {
      .splash-image-details {
        .splash-image-headings {
          h4,
          h3 {
            font-size: 14px;
          }
        }
      }
    }
  }
  .splash-locations {
    padding: 30px 50px;
    @media (max-width: 780px) {
      padding: 25px 20px;
    }
    .splash-options {
      margin: 0;
      padding: 0;
      display: grid;
      list-style-type: none;
      grid-template-columns: repeat(2, 1fr);
      column-gap: 30px;
      row-gap: 30px;
      cursor: pointer;
      .splash-option {
        background-color: #f7f7f7;
        border: 1px solid #e93434;
        border-radius: 10px;
        overflow: hidden;
        display: flex;
        position: relative;
        .splash-location-icon {
          position: absolute;
          right: 0;
          top: 0;
          bottom: 0;
          color: #e4e4e4;
          font-size: 100px;
          margin: 25px 10px;
          z-index: 0;
        }
        .splash-city-image {
          z-index: 1;
        }
        .splash-city-detail {
          z-index: 1;
          width: 50%;
          padding: 10px;
          display: flex;
          flex-direction: column;
          text-align: left;
          color: #606060;
          .splash-city-title {
            font-weight: 700;
            font-size: 24px;
          }
          .splash-city-address {
            font-weight: 700;
            font-size: 20px;
          }
          @media (max-width: 780px) {
            .splash-city-title {
              font-size: 20px;
            }
            .splash-city-address {
              font-size: 14px;
            }
          }
        }
      }
      @media (max-width: 780px) {
        grid-template-columns: repeat(1, 1fr);
        .splash-option {
          min-height: 110px;
          .splash-location-icon {
            margin: 5px 10px;
          }
          .splash-city-image {
            width: 110px;
            object-fit: cover;
          }
        }
      }
    }
  }
}
