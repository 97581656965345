.homepage-cities {
  background-image: url(../../assets/HomepageCities.png);
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  padding: 40px 0;
  .swiper {
    width: 85%;
    .swiper-pagination {
      bottom: 0;
      .swiper-pagination-bullet {
        height: 10px;
        width: 10px;
        opacity: 1;
        background-color: #e93434;
        margin-bottom: 2px;
      }
      .swiper-pagination-bullet-active {
        border: 2px solid white;
      }
    }
  }
  @media (max-width: 1190px) {
    .swiper {
      width: 95%;
    }
  }
  // @media (max-width: 1060px) {
  //   .swiper {
  //     width: 97%;
  //   }
  // }
}
