.homepage-info {
  padding: 33px 0px;
  height: auto;
  .homepage-info-cards-container {
    width: 90%;
    margin-left: auto;
    margin-bottom: 33px;
    height: auto;
    border-radius: 80px 0px 0px 80px;
    position: relative;
    img {
      object-fit: contain;
      height: auto;
      width: 100%;
    }
    .homepage-info-cards {
      height: 85%;
      width: 85%;
      position: absolute;
      top: 50%;
      left: 50%;
      display: flex;
      transform: translate(-50%, -50%);
      justify-content: space-around;
      .homepage-info-card {
        flex: 0.47;
        background-color: #292929;
        border-radius: 50px;
        color: #f6f6f6;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 25px;
        .homepage-dishes-order-btn {
            font-family: "Lato", sans-serif !important;
            line-height: 1;
            font-weight: 700;
            padding: 15px 40px;
            background-color: #e93434;
            color: white;
            border-radius: 69px;
            font-size: 24px;
            &:hover {
              background-color: rgba(233, 52, 52, 0.7);
            }
          }
        .homepage-info-card-price {
          position: absolute;
          top: 0;
          right: 0;
          width: 103px;
          height: 72px;
          background-color: #e93434;
          border-radius: 0 50px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: 700;
          font-size: 22px;
        }
        h3 {
          font-size: 45px;
          width: 70%;
        }
        h5 {
          font-size: 30px;
          font-family: "Merriweather", serif;
        }
        h6 {
          font-size: 24px;
          text-align: left;
        }
        h3,
        h6 {
          font-weight: 700;
        }
        h3,
        h5,
        h6 {
          text-align: left;
          line-height: 1;
          margin-bottom: 15px;
        }
        .warning {
          color: #FFFFFF;
        }
      }
    }
  }
  .homepage-info-details-container {
    height: auto;
    width: 90%;
    margin-right: auto;
    border-radius: 0px 80px 80px 0px;
    position: relative;
    img {
      object-fit: contain;
      height: auto;
      width: 100%;
    }
    .homepage-info-details {
      height: 85%;
      width: 95%;
      position: absolute;
      top: 50%;
      left: 50%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      transform: translate(-50%, -50%);
      color: white;
      h4 {
        font-weight: 700;
        font-size: 32px;
        margin-bottom: 15px;
        text-align: left;
      }
      h5 {
        font-family: "Merriweather", serif;
        font-weight: 400;
        font-size: 24px;
        text-align: left;
      }
    }
  }
  @media (max-width: 925px) {
    .homepage-info-cards-container {
      width: 100%;
      .homepage-info-cards {
        flex-direction: column;
        height: 95%;
      }
    }
    .homepage-info-details-container {
      width: 100%;
      .homepage-info-details {
        width: 85%;
        h5 {
          span {
            display: block;
            margin-top: 10px;
          }
        }
      }
    }
  }
  @media (max-width: 510px) {
    .homepage-info-cards-container {
      .homepage-info-cards {
        .homepage-info-card {
          h3 {
            font-size: 24px;
          }
          h5 {
            font-size: 12px;
          }
          h6 {
            font-size: 14px;
          }
          h3,
          h5,
          h6 {
            margin-bottom: 10px;
          }
        }
      }
    }
    .homepage-info-details-container {
      .homepage-info-details {
        h4 {
          font-size: 20px;
        }
        h5 {
          font-size: 16px;
        }
      }
    }
  }
}
