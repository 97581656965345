.contactus {
  padding-top: 120px;
  @media (max-width: 790px) {
    padding-top: 70px;
  }
  .contactus-map-area {
    width: 100%;
    .contactus-map-image {
      width: 100%;
    }
  }
  .contactus-details {
    margin: 3% 10%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .contactus-details-title {
      font-weight: 700;
      font-size: 48px;
      color: #e93434;
    }
    .contactus-details-container {
      width: 100%;
      margin-top: 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      color: #606060;
      font-size: 32px;
      h3 {
        font-weight: 700;
        font-size: 32px;
        margin-bottom: 30px;
      }
      .contactus-data {
        display: flex;
        align-items: center;
        margin-bottom: 30px;
        svg {
          margin-right: 20px;
          font-size: 35px;
          fill: #606060;
          min-width: 35px;
        }
        p {
          margin: 0;
          text-align: left;
          font-weight: 700;
          font-size: 32px;
        }
      }
    }
  }
  .contactus-form-container {
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 1) 28%,
      #e93434 28%,
      #e93434 100%
    );
    margin: 50px 0;
    padding: 10% 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    svg {
      max-width: 34%;
      width: 100%;
      margin-right: 7%;
    }
    .contactus-form {
      border: 4px solid #e93434;
      width: 40%;
      min-height: 70%;
      margin-right: 7%;
      background-color: #f7f7f7;
      padding: 3%;
      .form-input {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 20px;
        width: 100%;

        label,
        div {
          width: 100%;
          font-weight: 700;
          font-size: 20px;
        }
        label {
          text-align: left;
          margin-bottom: 10px;
        }
        div {
          font-weight: 400;
          letter-spacing: 1px;
          border-radius: 15px;
          .MuiOutlinedInput-notchedOutline {
            border: 1px solid #e93434;
            legend {
              display: none;
            }
          }
        }
      }
      .form-submit-btn {
        background: #e93434;
        border-radius: 5px;
        font-weight: 700;
        font-size: 20px;
        line-height: 1;
        text-transform: capitalize;
        color: white;
        padding: 10px 30px;
        float: right;
      }
    }
  }
  @media (max-width: 800px) {
    .contactus-details {
      .contactus-details-title {
        font-size: 24px;
      }
      .contactus-details-container {
        h3 {
          font-size: 20px;
        }
        .contactus-data {
          margin-bottom: 15px;
          svg {
            font-size: 25px;
            min-width: 25px;
          }
          p {
            font-size: 14px;
          }
        }
      }
    }
    .contactus-form-container {
      padding: 0;
      background: white;
      svg {
        display: none;
      }
      .contactus-form {
        width: 100%;
        margin: 0 7%;
        padding: 25px 35px;
      }
    }
  }
}
